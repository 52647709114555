import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./profilepicture.css";
import Button from "../../../../../components/button";
import { toast } from "react-toastify";
//services
import { updateProfilePicture } from "../../../../../services/medium";
import { useDispatch, useSelector } from "react-redux";
import { requestScreenNameChange } from "../../../../../services/profile";
import { setProfileImage } from "../../../../../reducer/userSlice";
import { Link, useParams } from "react-router-dom";
import { copyToClipboard } from "../../../../../utils/helpers";
//import moment from "moment";
//import { da } from "date-fns/locale";

const defaultSrc =
  "https://lh3.googleusercontent.com/i7cTyGnCwLIJhT1t2YpLW-zHt8ZKalgQiqfrYnZQl975-ygD_0mOXaYZMzekfKW_ydHRutDbNzeqpWoLkFR4Yx2Z2bgNj2XskKJrfw8";

export default function ProfilePicture({ mediumship_level_admin }) {
  const dispatch = useDispatch();
  // const params = useParams();
  const { profile_image, encoded_image, encrypted_id } = useSelector(
    (state) => state.user.user
  );
  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [popup, setPopup] = useState(false);
  //const [message, setMessage] = useState("");
  //const [selectedFile, setselectedFile] = useState(null);
  const [copy, setCopy] = useState(false);
  const [copy1, setCopy1] = useState(false);

  const copyText = (e, value) => {
    let location = window.location.origin;
    let url = `${location}/embeded/${encrypted_id}`;
    setCopy(true);
    e.stopPropagation();
    copyToClipboard(`<iframe width="100%" height="320"  src="${url}"  />`);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };
  const copyText1 = (e, value) => {
    let location = window.location.origin;
    let url = `${location}/embeded-code/${encrypted_id}`
    setCopy1(true);
    e.stopPropagation();
    copyToClipboard(url);
    setTimeout(() => {
      setCopy1(false);
    }, 3000);
  };
  useEffect(() => {
    setImage(encoded_image);
  }, [profile_image]);

  const closePopup = () => setPopup(false);
  const openPopup = () => {
    setPopup(true);
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      console.log(reader.result);
      setImage(reader.result);
    };
    console.log(files[0]);
    reader.readAsDataURL(files[0]);
  };
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());

      cropper.getCroppedCanvas().toBlob((blob) => {
        console.log(blob, "nlocjidjfc");
        updalodaImage(blob);
      });
    }
  };

  const updalodaImage = (file) => {
    console.log(file);
    // setImage(file);
    const formData = new FormData();
    formData.append(profile_image === null ? "image" : "profile_image", file);
    if (profile_image !== null) {
      formData.append("type", "profile_image");
      requestScreenNameChange(formData).then((data) => {
        if (data.data.status) {
          closePopup();
          toast.success("Requested succesfully", { position: "top-center" });
        }
      });
    } else {
      updateProfilePicture(formData)
        .then((data) => {
          dispatch(setProfileImage(data.data.data.image));
          toast.success("Updated succesfully", { position: "top-center" });
        })
        .catch(() => { });
    }
  };
  return (
    <div className="row-one-step">
      <>
        {console.log(mediumship_level_admin, "mediumship_level_admin")}
        {(parseInt(mediumship_level_admin) === 3 ||
          parseInt(mediumship_level_admin) === 6 ||
          parseInt(mediumship_level_admin) === 7) && (
            <>
              {" "}
              <div className="d-flex align-items-center justify-content-end pointer">
                <Link
                  className="public-profile-btn"
                  to={`/public-view`}
                  target="_blank"
                >
                  View Public Profile
                </Link>
              </div>
              <div className="d-flex align-items-center justify-content-end pointer mt-2">
                <div>
                  <Button
                    status={0}
                    type="button"
                    className={`resend-btn w-unset height-45 min-width-130px`}
                    onClick={copyText}
                    title={copy ? "Copied" : "Copy Embedded"}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end pointer mt-2">
                <div>

                  <Button
                    status={0}
                    type="button"
                    className={`resend-btn w-unset height-45 min-width-130px`}
                    onClick={copyText1}
                    title={copy1 ? "Copied" : "Copy Guest Link"}
                  />

                </div>
              </div>
            </>
          )}
        {/* <iframe src={'http://localhost:3000/my-profile'} /> */}
        <div className="d-flex align-items-center">
          <div className="profile-pic-new">
            <img
              className="main-profile-image"
              id="main-profile-image"
              src={
                profile_image !== null
                  ? `${process.env.REACT_APP_IMAGE_URL}${profile_image}`
                  : "images/user-dummy.png"
              }
            />
          </div>
          <div className="ml-2">
            {/* <span class="cropper-btn-file resend-btn w-unset height-45 min-width-130px">
                            {profile_image === null ? "Upload" : "Request Change"}<input type="file" onChange={onChange} />
                        </span> */}
            <Button
              status={0}
              type="button"
              className={`resend-btn w-unset height-45 min-width-130px`}
              onClick={openPopup}
              // title={requestChangePhoneNo ? "Submit" : "Request Change"}
              title={"Request Change"}
            />
            {/* {toast.success(message, { position: "top-center" })} */}
            {/* <span className="green font-size-11 d-block mt-1"> {message}</span> */}
          </div>

          {popup && (
            <div className="cropper-wrapper">
              <div className="cropper-container-inner">
                <span class="cropper-btn-file resend-btn w-unset height-45 min-width-130px">
                  Choose Image...
                  <input type="file" onChange={onChange} />
                </span>

                <Cropper
                  style={{
                    height: 400,
                    width: 500,
                    backgroundColor: "gray",
                    opacity: 1,
                  }}
                  zoomTo={0.1}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  dragMode="move"
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={true}
                  responsive={true}
                  rounded={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
                <Button
                  status={0}
                  type="button"
                  className={`resend-btn w-unset height-45 min-width-130px mt-1 pointer`}
                  onClick={getCropData}
                  // title={requestChangePhoneNo ? "Submit" : "Request Change"}
                  title={"Upload"}
                />
                <Button
                  status={0}
                  type="button"
                  className={`resend-btn w-unset height-45 min-width-130px ml-2 mt-1 pointer`}
                  onClick={closePopup}
                  // title={requestChangePhoneNo ? "Submit" : "Request Change"}
                  title={"Cancel"}
                />
              </div>
            </div>
          )}
        </div>
        {/* <div className="pfil-name">
                    <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                </div> */}
      </>
    </div>
  );
}
