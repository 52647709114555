import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProfileBasicInfo } from "../../../../services/medium";
import StarsRating from "stars-rating";
// import BookingPopUp from "./bookingpopup";
import "./profile.css";
import { deleteUserFeedbacks } from "../../../../services/admin";
export default function MediumView({ onClick }) {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  //const [profile, setProfile] = useState(null);
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const [profileSection, setProfileSection] = useState(null);
  //const [teachnologySection, setteachnologySection] = useState(null);
  //const [preferncesSection, setpreferncesSection] = useState(null);

  //const params = useParams();

  useEffect(() => {
    // dispatch(setPathArray(params.id));
    getProfile();
    // getPreferences();
    // getTechnologies();
    // return () => {
    // dispatch(setPathArray(null));
    // };
  }, []);

  const getProfile = () => {
    getProfileBasicInfo()
      .then((data) => {
        setProfileSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const calculateUnique = (arr) => {
    let value = arr.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
      return acc;
    }, {});
    return value;
  };
  const calculateRating = (feedbacks) => {
    let total = 0;
    let sum_resp = 0;
    let counts = feedbacks.map((item) => {
      return parseInt(JSON.parse(item.feedback).Star_Rating);
    });

    Object.entries(calculateUnique(counts)).map(([key, value]) => {
      sum_resp += parseInt(value) * parseInt(key);
      total += parseInt(value);
    });
    let score = Math.round((sum_resp / total) * 2) / 2;
    return score;
    // return Math.floor(sum_resp / total);
  };
  // const getPreferences = () => {
  //     getProfilePreferences()
  //         .then((data) => {

  //             setpreferncesSection(data.data.data);
  //         })
  //         .catch((err) => console.log(err));
  // };
  // const getTechnologies = () => {
  //     getProfileTechnology()
  //         .then((data) => {
  //             setteachnologySection(data.data.data);
  //         })
  //         .catch((err) => console.log(err));
  // };
  return (
    <div className="schedule-advance-box-all-container">
      <div class="single-profile-saction single-profile-saction-new">
        {/* <div onClick={() => navigate(-1)} class="back-btn-profile">
          {from !== "booking" &&
            <div className="bac-href pointer">
              <img className="bac-img" src="/images/arrow-back.png" alt="back" />
              Go Back
            </div>
          }
        </div> */}
        <div class="profile-all-box">
          <div class="banner-img-profile">
            <img src="/images/profile-cover.png" alt="avatar" />
          </div>
          <div class="profile-info app-new-profile">
            <div class="profile-image">
              <img
                className="public-profile-picture"
                src={`${profileSection !== null && profileSection.profile_image !== ""
                    ? process.env.REACT_APP_IMAGE_URL +
                    profileSection.profile_image
                    : "/images/user-dummy.png"
                  }`}
                alt="avatar"
              />
            </div>
            <div class="profile-name">
              <div class="nam-prof">
                {profileSection !== null && profileSection.screen_name}
              </div>
              <a href="#">
                {profileSection !== null && profileSection.screen_name}
              </a>
              <div class="star-rating">
                {profileSection !== null && (
                  <StarsRating
                    count={5}
                    value={calculateRating(profileSection.feedbacks)}
                    size={20}
                    color2={"#ffc107"}
                    edit={false}
                    color1={"gray"}
                  />
                )}
                {/* {profileSection !== null &&  Array.from(Array(5).keys()).map((item) => (
                  <>
                    {item + 1 <=
                    parseInt(calculateRating(profileSection.feedbacks)) ? (
                      <img src={"localImages/star.png"} alt="star" />
                    ) : (
                      <img src="localImages/hollowstar.svg" alt="white star" />
                    )}
                  </>
                ))} */}
                {/* <span>0</span>{" "} */}
                {/* <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" />
                <img src="localImages/hollowstar.svg" alt="white star" /> */}
                {/* <em>0 Ratings</em> */}
              </div>
            </div>
          </div>

          {from !== "booking" && <div className="profile-book-now">
            {/* <button type="button" onClick={openPopup}>Book Now</button> */}
            <a
              className="book-now-practice"
              href={
                profileSection !== null &&
                  profileSection.personal_booking_link !== ""
                  ? profileSection.personal_booking_link
                  : "#"
              }
            >
              Book Here
            </a>
          </div>}

          <div class="profile-details app-new-profile-details">
            <ul>
              <li>
                <div class="icon-detls">
                  <img src="/images/chate.png" alt="" />
                </div>
                <em>Languages Available for Mediumship Sessions:</em>
                {profileSection !== null &&
                  profileSection.languages.map((data) => (
                    <span>{data.lanugage_name}</span>
                  ))}
              </li>
              {/* <li>
                <div class="icon-detls">
                  <img src="/images/present.png" alt="" />
                </div>
                <em>Mediumship Specialities:</em>
                {profileSection !== null &&
                  profileSection.specialities.map((data) => (
                    <span>{data.specialty_name}</span>
                  ))}
              </li> */}
              <li>
                <div class="icon-detls">
                  <img src="/images/word.png" alt="" />
                </div>
                <em>Country:</em>
                <a href="#">
                  {profileSection !== null && profileSection.country}
                </a>
              </li>
              {/* <li>
                <div class="icon-detls">
                  <img src="images/web.png" />
                </div>
                <em>Website:</em>
                <a href="#">{PractitionerInfo.general.website}</a>
              </li> */}
            </ul>
          </div>
          <div class="profile-bio">
            <h2>About Me</h2>
            <p>{profileSection !== null && profileSection.info}</p>
          </div>
          <div class="profile-bio">
            <h2 className="mb-3">
              Client Reviews{" "}
              {profileSection !== null && profileSection.recommendation !== ""
                ? `: ${profileSection.recommendation}% Recommend`
                : " "}
            </h2>

            <div className="feedback-container">
              {profileSection !== null &&
                profileSection.feedbacks.map((feedback) => (
                  <div className="feedback-border-feed mt-3 d-flex align-items-center ">
                    <div className="min-width-200px">
                      <div className="d-flex align-items-center">
                        <div className="feedback-image">
                          <img
                            className="practioner-profile-image"
                            src="../images/user-dummy.png"
                            alt="avatar"
                          />
                        </div>
                        <span className="ml-3">
                          {feedback.client_name} -{" "}
                          {JSON.parse(feedback.feedback).Country}
                        </span>
                      </div>
                      <div className="feedback-star-image">
                        <StarsRating
                          count={5}
                          value={parseInt(
                            JSON.parse(feedback.feedback).Star_Rating
                          )}
                          size={20}
                          color2={"#ffc107"}
                          edit={false}
                          color1={"#ffffff"}
                        />
                        {/* {Array.from(Array(5).keys()).map((item) => (
                                                <>
                                                    {item + 1 <=
                                                        parseInt(
                                                            JSON.parse(feedback.feedback).Star_Rating
                                                        ) ? (
                                                        <img src={"localImages/star.png"} />
                                                    ) : (
                                                        <img
                                                            src="localImages/hollowstar.svg"
                                                            alt="white star"
                                                        />
                                                    )}
                                                </>
                                            ))} */}
                      </div>
                      <p className="feedback-date">{feedback.feedback_date}</p>
                    </div>
                    <div>
                      <span className="margin-unset line-height-unset feedback-heading">
                        {JSON.parse(feedback.feedback).Title}
                      </span>
                      <span className="margin-unset line-height-unset">
                        {JSON.parse(feedback.feedback).Publishable_Feedback}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* <BookingPopUp item={PractitionerInfo} /> */}
    </div>
  );
}
