import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import {
  getLimit,
  setSessionLimit,
  setRescheduleLimit,
  setBanner,
  getBannerPage,
  setOffDemandAll,
  importFeedbacks,
  setBannerMedium,
  setBannerOutreachClients,
  setBannerStarterClients,
  getIsReschedule
} from "../../../services/admin";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import './support.css';
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import { getLinkedPaymentToken, savePaymentMedtods } from "../../../services/medium";


const listArray = [
  { id: 1, name: "Limits", },
  { id: 2, name: "Banner", },
  { id: 2, name: "Coaching Session", },
  { id: 3, name: "Reschedule Setting", },
  { id: 4, name: "Pay It Forward Integration", },
  { id: 5, name: "Tracking Data", },
]
export default function Support() {
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("Enter Limit");
  const [limit, setLimit] = useState("");
  const [sessions, setSessions] = useState([]);
  const [bannerLimit, setBannerLimit] = useState(0);
  const [bannerDesc, setBannerDesc] = useState("");
  const [onDemandStatus, setonDemandStatus] = useState(1);
  const [underMaintenance, setunderMaintenance] = useState(0)
  const [mediumBanner, setMEediumBanner] = useState('')
  const [outreachClientsBanner, setoutreachClientsBanner] = useState('')
  const [starterClientsBanner, setstarterClientBanner] = useState('')
  const [file, setFile] = useState();

  const [mediumBannerToogle, setMediumBannerToogle] = useState(0);
  const [outreachBannerToogle, setOutreachBannerToogle] = useState(0);
  const [starterBannerToogle, setstarterBannerToogle] = useState(0);
  const [activeTab, setActiveTab] = useState(0)
  const [daysvalue, setDaysValue] = useState("");
  const [rescheduleTime, setRescheduleTime] = useState("");
  const [appontmentvalue, setAppointmentValue] = useState("");
  const [checkedSessions, setCheckedSessions] = useState([]);
  const [selectedTime, setSelectedTime] = useState('days');
  const [formValidate, setformValidate] = useState(false);
  const [typestripe1, SetTypeStripe1] = useState(true);
  const [typestripe2, SetTypeStripe2] = useState(true);
  const [str_pub_key, setstr_pub_key] = useState("");
  const [str_pri_key, setstr_pri_key] = useState("");


  const [typepaypal1, SetTypePaypal1] = useState(true);
  const [typepaypal2, SetTypePaypal2] = useState(true);
  const [paypal_pub_key, setpaypal_pub_key] = useState("");
  const [paypal_pri_key, setpaypal_pri_key] = useState("");
  const [isMainAdmin, setisMainAdmin] = useState("");
  const {mainAdmin} = useSelector((state) => state.user.user);

  useEffect(() => {
    getPaymentToken();
    callBack();
    setisMainAdmin(mainAdmin);
  }, []);
  const changePassType = (type) => {
    if (type === 1) {
      SetTypeStripe1(!typestripe1);
    } else if (type === 2) {
      SetTypeStripe2(!typestripe2);
    } else if (type === 3) {
      SetTypePaypal1(!typepaypal1);
    } else if (type === 4) {
      SetTypePaypal2(!typepaypal2);
    }
  }

  const callBack = () => {
    getLimit("under_maintenance")
      .then((data) => {
        console.log(data);
        if (data.data.status) {
          setunderMaintenance(parseInt(data.data.data.value));
        }
        // setLimit()
      })
      .catch(() => { });
    getLimit("session_limit")
      .then((data) => {
        console.log(data);
        if (data.data.status) {
          setLimit(data.data.data.value);
        }
        // setLimit()
      })
    getLimit("session_type")
      .then((data) => {
        if (data.data.status) {
          if (data.data.data !== null) {
            console.log(data.data.data.value, "aa");
            let sessionTypeValue = data.data.data.value;
            const arrayValue = sessionTypeValue.split(",").map(Number);
            setCheckedSessions(arrayValue);
          } else {
            console.log("Session_type data not found")
          }
        }
        // setLimit()
      })
      .catch(() => { });
    getLimit("frequency")
      .then((data) => {
        if (data.data.status) {
          if (data.data.data !== null) {
            let frequencyValue = data.data.data.value;
            const number = parseInt(frequencyValue);
            const text = frequencyValue.replace(/\d+/, '').trim();
            setRescheduleTime(number);
            setSelectedTime(text);
          } else {
            console.log("frequency data not found")
          }
        }
        // setLimit()
      })
      .catch(() => { });
    getIsReschedule()
      .then((data) => {
        if (data.data.data) {
          setSessions(data.data.data);
        }

      })
      .catch(() => { });

    getLimit("coaching_day_limit")
      .then((data) => {
        console.log(data);
        if (data.data.status) {
          setDaysValue(data.data.data.value);
        }
        // setLimit()
      })
      .catch(() => { });
    getLimit("coaching_appointment_limit")
      .then((data) => {
        console.log(data);
        if (data.data.status) {
          setAppointmentValue(data.data.data.value);
        }
        // setLimit()
      })
      .catch(() => { });
    getBannerPage()
      .then((data) => {
        if (data.data.status) {
          let info = data.data.data[0];
          let inf = data.data.data
          setBannerLimit(parseInt(info.status));
          setBannerDesc(info.description);
          setMEediumBanner(inf.medium)
          setstarterClientBanner(inf.starter)
          setoutreachClientsBanner(inf.outreach)
          setMediumBannerToogle(inf.medium_is_enabled)
          setOutreachBannerToogle(inf.outreach_is_enabled)
          setstarterBannerToogle(inf.starter_is_enabled)

        }
      })
      .catch(() => { });
  };

  const onChangeHandler = (e) => {
    setValue(e.target.value);
  };
  const onsetCochingLimitsHandler = (type) => {
    if (type === "days") {
      let body = {
        limit: daysvalue,
        key: "coaching_day_limit",
      };
      setSessionLimit(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Days limit updated", { position: "top-center" });
          }
        })
        .catch(() => {

        });
    }

    else {
      let body = {
        limit: appontmentvalue,
        key: "coaching_appointment_limit",
      };
      setSessionLimit(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Appointment limit updated", { position: "top-center" });
          }
        })
        .catch(() => {

        });
    }
  }
  useEffect(() => {
    if (value === "") {
      setMessage("Please fill Limit");
    }
  }, [value]);
  const onClickHandler = () => {
    if (value === "") {
      setMessage("Please fill Limit");
    }
    if (value !== "") {
      let body = {
        limit: value,
        key: "session_limit",
      };
      setSessionLimit(body)
        .then((data) => {
          if (data.data.status) {
            setMessage(`New Limit is ${value}`);
          } else {
            setMessage(`Please Try Again`);
          }
        })
        .catch(() => {
          setMessage(`Please Try Again`);
        });
    }
  };
  const onChangeBannerToggle = (e, type) => {
    let check = e.target.checked;
    let body;
    if (type === "medium") {
      body = {
        is_enabled: check ? 1 : 0,
        banner_text: mediumBanner,

      };
      setBannerMedium(body)
        .then((data) => {
          if (data.data.status) {
            setMediumBannerToogle(check ? 1 : 0);
            toast.success("Medium Banner Status Updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }
    else if (type === "outreachclients") {
      body = {
        is_enabled: check ? 1 : 0,
        banner_text: outreachClientsBanner,

      };
      setBannerOutreachClients(body)
        .then((data) => {
          if (data.data.status) {
            setOutreachBannerToogle(check ? 1 : 0);
            toast.success("Outreach Clients Banner Status Updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }
    else if (type === "starterclients") {
      body = {
        is_enabled: check ? 1 : 0,
        banner_text: starterClientsBanner,

      };
      setBannerStarterClients(body)
        .then((data) => {
          if (data.data.status) {
            setstarterBannerToogle(check ? 1 : 0);
            toast.success("Starter Clients Banner Status Updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }

  }
  const onSetUnderMaintenance = (e) => {
    let check = e.target.checked;
    let body = {
      limit: check ? 1 : 0,
      key: "under_maintenance",

    };

    setSessionLimit(body)
      .then((data) => {
        if (data.data.status) {
          setunderMaintenance(check);
          toast.success("Status Updated", { position: "top-center" });
        } else {
          toast.error("Try again!", { position: "top-center" });
        }
      })
      .catch(() => {
        toast.error("Try again!", { position: "top-center" });
      });
  }

  const onSetBanner = () => {
    let body = {
      description: bannerDesc,
      status: bannerLimit ? 1 : 0,
    };
    setBanner(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Banner Updated", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const onSetBannerStatus = (e) => {
    let check = e.target.checked;
    let body = {
      description: bannerDesc,
      status: check ? 1 : 0,
    };
    setBanner(body)
      .then((data) => {
        if (data.data.status) {
          setBannerLimit(check);
          toast.success("Banner Status Updated", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const onSetOndemandStaus = (e) => {
    let check = e.target.checked;
    let body = {
      status: check ? 1 : 0,
    };
    setOffDemandAll(body)
      .then((data) => {
        if (data.data.status) {
          setonDemandStatus(check);
          toast.success("Status Updated", { position: "top-center" });
        }
      })
      .catch(() => { });
  };

  const uploadFeedback = (formData) => {
    importFeedbacks(formData)
      .then((data) => {
        if (data.data.status) {
          toast.success("File Uploaded", { position: "top-center" });
        }
        else {
          toast.error("Error In Uploading File", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const chooseUploadFile = (e) => {
    if (e.target.files) {
      console.log(e.target.files[0]);
      setFile(e.target.files[0]);
    }
  };
  const submitFileHandler = () => {
    const formData = new FormData();
    // formData.append('fileName', file.name);
    formData.append('file', file);
    console.log(formData, "formDataformDataformData")
    uploadFeedback(formData)
  };

  const onSetBannerHandler = (type, value) => {
    let body = {
      banner_text: value,

    };
    if (type === "medium") {
      body = {
        is_enabled: mediumBannerToogle,
        banner_text: value,

      };
      setBannerMedium(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Medium Banner Updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }
    else if (type === "outreachclients") {
      body = {
        is_enabled: outreachBannerToogle,
        banner_text: value,

      };
      setBannerOutreachClients(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Outreach Clients Banner Updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }
    else if (type === "starterclients") {
      body = {
        is_enabled: starterBannerToogle,
        banner_text: value,

      };
      setBannerStarterClients(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Starter Clients Banner Updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }
  };
  const handleCheckboxChange = (id) => {
    setCheckedSessions(prevIds => {
      if (prevIds.includes(id)) {
        return prevIds.filter(prevId => prevId !== id); // Remove id if already present
      } else {
        return [...prevIds, id]; // Add id if not present
      }
    });
  };
  const onReschedule = () => {
    setformValidate(false);
    if (checkedSessions.length > 0 && rescheduleTime && selectedTime) {
      let commaSeparatedString;
      if (checkedSessions.length > 0) {
        commaSeparatedString = checkedSessions.join(', ');
      } else {
        commaSeparatedString = "";
      }
      let feq = `${rescheduleTime} ${selectedTime}`;
      let body = {
        session_type: commaSeparatedString,
        frequency: feq,
        key: "session_type",
        key1: "frequency"
      }
      setRescheduleLimit(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Reschedule limit updated", { position: "top-center" });
          }
        })
        .catch(() => {

        });
    } else {
      setformValidate(true);
    }


  }
  const savePaymentMethods = (info) => {
    console.log(info)
    console.log(paypal_pub_key, paypal_pri_key)

    if (info === "paypal") {
      if (paypal_pub_key === paypal_pri_key) {
        toast.error("Wrong paypal Keys", { position: "top-center" });
        return;

      }
    }


    let body = {
      // str_pub_key: str_pub_key,
      // str_pri_key: str_pri_key,
      paypal_pub_key: paypal_pub_key,
      paypal_pri_key: paypal_pri_key,
      // squareup_access_token: squareup_access_token,
      // square_location_id: square_location_id
    };

    savePaymentMedtods(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Updated", { position: "top-center" });
          window.loadModal2("#payment-modal", "hide");
        }
      })
      .catch(() => { });
  };
  const paymentChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "str_pub_key") {
      setstr_pub_key(value);
    }
    if (name === "str_pri_key") {
      setstr_pri_key(value);
    }
    if (name === "paypal_pub_key") {
      setpaypal_pub_key(value);
    }
    if (name === "paypal_pri_key") {
      setpaypal_pri_key(value);
    }

  };
  const clearPaymentField = () => {

    let body = {
      paypal_pub_key: "",
      paypal_pri_key: "",

    };

    savePaymentMedtods(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Cleared Payments", { position: "top-center" });
          window.loadModal2("#payment-modal", "hide");
        }
      })
      .catch(() => { });
    setpaypal_pri_key("");
    setpaypal_pub_key("");



  };
  const getPaymentToken = () => {
    getLinkedPaymentToken()
      .then((data) => {
        if (data.data.status) {
          setpaypal_pri_key(data.data.data.paypal_pri_key);
          setpaypal_pub_key(data.data.data.paypal_pub_key);
        }
      })
      .catch(() => { });
  };
  return (
    <div className="all-medium-page min-height-80vh">
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />
      <ul className="tabs-content">
        <TabPanel id={0} activeTab={activeTab}>
          <div className="mt-5">
            Previous Limit : {limit}
            <div className="mt-2">
              <input onChange={onChangeHandler} value={value} placeholder="Limit" />
              <Button
                status={0}
                onClick={onClickHandler}
                className={"proceed-btn ml-2"}
                title={"Set Limit"}
                type={"button"}
              />
            </div>
            <span className="mt-2 d-block">{message}</span>
          </div>



          {/************* */}
          <div className="mt-5">
            <div className="mt-2">
              <div className="mb-3">
                <label className="mb-4">On Demand Status</label>
                <div className="full-fild">
                  <div class="toggle-button-cover">
                    <div class="button-cover">
                      <div class="button r system-update-btn" id="button-1">
                        <input
                          type="checkbox"
                          value={onDemandStatus}
                          checked={onDemandStatus ? true : false}
                          name="toggle"
                          class="checkbox"
                          onChange={onSetOndemandStaus}
                        />
                        <div class="knobs new-knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/************* */}
          <div className="mt-5">
            <div className="mt-2">
              <div className="mb-3">
                <label className="mb-4">Import Feedbacks</label>
                <div className="full-fild">
                  <div >
                    <div>
                      <div>
                        <input
                          type="file"
                          // value={onDemandStatus}
                          name="file"
                          onChange={chooseUploadFile}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <Button
                        status={0}
                        onClick={submitFileHandler}
                        className={"proceed-btn ml-2"}
                        title={"Submit File"}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/************* */}
          <div className="mt-5">
            <div className="mt-2">
              <div className="mb-3">
                <label className="mb-4">Under Contruction</label>
                <div className="full-fild">
                  <div class="toggle-button-cover">
                    <div class="button-cover">
                      <div class="button r system-update-btn" id="button-1">
                        <input
                          type="checkbox"
                          value={underMaintenance}
                          checked={underMaintenance ? true : false}
                          name="toggle"
                          class="checkbox"
                          onChange={onSetUnderMaintenance}
                        />
                        <div class="knobs new-knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        {/************* */}
        <TabPanel id={1} activeTab={activeTab}>
          <div className="d-flex align-items-center support-cards">
            <div className="mt-5">
              <div className="mt-2">
                <div className="">
                  <label className="mb-2">Login Banner Text - Mediums</label>
                  <div className="full-fild d-inline-block ml-5 mb-2">
                    <div class="toggle-button-cover">
                      <div class="button-cover">
                        <div class="button r system-update-btn" id="button-1">
                          <input
                            type="checkbox"
                            value={mediumBannerToogle}
                            checked={mediumBannerToogle === 1 ? true : false}
                            name="toggle"
                            class="checkbox"
                            onChange={(e) => onChangeBannerToggle(e, 'medium')}
                          />
                          <div class="knobs new-knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  rows="8" cols="40"
                  onChange={(e) => setMEediumBanner(e.target.value)}
                  value={mediumBanner}
                  placeholder="Mediums Banner"
                  className="mt-2 p-2"
                />
              </div>
              <Button
                status={0}
                onClick={() => onSetBannerHandler('medium', mediumBanner)}
                className={"proceed-btn ml-2"}
                title={"Enter Mediums Banner Text"}
                type={"button"}
              />
            </div>

          </div>

          {/************* */}

          <div className="d-flex align-items-center support-cards">
            <div className="mt-5">
              <div className="mt-2">
                <div className="">
                  <label className="mb-2"> Login Banner Text - Outreach Clients</label>
                  <div className="full-fild d-inline-block ml-5 mb-2">
                    <div class="toggle-button-cover">
                      <div class="button-cover">
                        <div class="button r system-update-btn" id="button-1">
                          <input
                            type="checkbox"
                            value={outreachBannerToogle}
                            checked={outreachBannerToogle === 1 ? true : false}
                            name="toggle"
                            class="checkbox"
                            onChange={(e) => onChangeBannerToggle(e, 'outreachclients')}
                          />
                          <div class="knobs new-knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  rows="8" cols="40"
                  onChange={(e) => setoutreachClientsBanner(e.target.value)}
                  value={outreachClientsBanner}
                  placeholder=" Outreach Clients Banner"
                  className="mt-2 p-2"
                />
              </div>
              <Button
                status={0}
                onClick={() => onSetBannerHandler('outreachclients', outreachClientsBanner)}
                className={"proceed-btn ml-2"}
                title={"Enter Outreach Client Banner Text"}
                type={"button"}
              />
            </div>

          </div>

          <div className="d-flex align-items-center support-cards">
            <div className="mt-5">
              <div className="mt-2">
                <div className="">
                  <label className="mb-2"> Login Banner Text - Starter Clients</label>
                  <div className="full-fild d-inline-block ml-5 mb-2">
                    <div class="toggle-button-cover">
                      <div class="button-cover">
                        <div class="button r system-update-btn" id="button-1">
                          <input
                            type="checkbox"
                            value={starterBannerToogle}
                            checked={starterBannerToogle === 1 ? true : false}
                            name="toggle"
                            class="checkbox"
                            onChange={(e) => onChangeBannerToggle(e, 'starterclients')}
                          />
                          <div class="knobs new-knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  rows="8" cols="40"
                  onChange={(e) => setstarterClientBanner(e.target.value)}
                  value={starterClientsBanner}
                  placeholder="Banner"
                  className="mt-2 p-2"
                />
              </div>
              <Button
                status={0}
                onClick={() => onSetBannerHandler('starterclients', starterClientsBanner)}
                className={"proceed-btn ml-2"}
                title={"Enter Starter Client Banner Text"}
                type={"button"}
              />
            </div>

          </div>
          <div className="d-flex align-items-center support-cards">
            <div className="mt-5">
              <div className="mt-2">
                <div className="mb-3">
                  <label className="mb-4">Banner for Client Booking Page - Outreach & Starter Clients</label>
                  <div className="full-fild">
                    <div class="toggle-button-cover">
                      <div class="button-cover">
                        <div class="button r system-update-btn" id="button-1">
                          <input
                            type="checkbox"
                            value={bannerLimit}
                            checked={bannerLimit ? true : false}
                            name="toggle"
                            class="checkbox"
                            onChange={onSetBannerStatus}
                          />
                          <div class="knobs new-knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="prvacy-box eth-pri">
                <input
                  onChange={(e) => setBannerLimit(e.target.checked)}
                  type="checkbox"
                  id="banner_status"
                  value={bannerLimit}
                  name="banner_status"
                  checked={bannerLimit ? true : false}
                />
                <label htmlFor="banner_status">Banner Text Status</label>
              </div> */}
                  </div>
                </div>
                <input
                  onChange={(e) => setBannerDesc(e.target.value)}
                  value={bannerDesc}
                  placeholder="Banner"
                  className="mt-2"
                />
                <Button
                  status={0}
                  onClick={onSetBanner}
                  className={"proceed-btn ml-2"}
                  title={"Enter Banner"}
                  type={"button"}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <div className="mt-2">
            <input type="number" onChange={(e) => setDaysValue(e.target.value)} value={daysvalue} placeholder="Days" />
            <Button
              status={0}
              onClick={() => onsetCochingLimitsHandler('days')}
              className={"proceed-btn ml-2"}
              title={"Set Days"}
              type={"button"}
            />
          </div>
          <div className="mt-2">
            <input type="number" onChange={(e) => setAppointmentValue(e.target.value)} value={appontmentvalue} placeholder="Appointments" />
            <Button
              status={0}
              onClick={() => onsetCochingLimitsHandler('appointments')}
              className={"proceed-btn ml-2"}
              title={"Set Appointments"}
              type={"button"}
            />
          </div>
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          {
            formValidate && (
              <>
                <div className="alert alert-danger" role="alert">
                  Please fill the form.
                </div>
              </>
            )
          }

          <div className="form-group row">
            <select name="time" className="form-control" id="time" value={selectedTime} // Set the value of the dropdown to the state
              onChange={(e) => setSelectedTime(e.target.value)}>
              <option value="days">Days</option>
              <option value="hours">Hours</option>
              <option value="minutes">Minutes</option>
            </select>
            <div className="col-sm-10">
              <input type="number" onChange={(e) => setRescheduleTime(e.target.value)} value={rescheduleTime} placeholder="Days , Hours, Minutes" />

            </div>
          </div>
          <div className="row">

            <div className="col-sm-12">
              <label className="mb-2">Select session to apply</label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12" >
              {sessions.map(session => (
                <div key={session.id} className="form-check mb-1">
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox-style"
                      checked={checkedSessions.includes(session.id)}
                      onChange={() => handleCheckboxChange(session.id)}
                    />
                    <label className="form-check-label" htmlFor={`session-${session.id}`} style={{ marginLeft: '10px' }}>
                      {session.session_name}
                    </label>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Button
                status={0}
                onClick={() => onReschedule()}
                className={"proceed-btn ml-2"}
                title={"Set Reschedule"}
                type={"button"}
              />
            </div>
          </div>



        </TabPanel>
        <TabPanel id={4} activeTab={activeTab}>

          <div class="box-popup-inte intepopup_one position-relative">
            <div className="d-flex">
              <div>
                <div className="">
                  <div>
                    <label className="payment-methods-container-inner-label">
                      Public key
                    </label>
                    <div className="payment-methods-container-inner">
                      <input
                        value={paypal_pub_key}
                        name="paypal_pub_key"
                        className="mt-2"
                        onChange={paymentChangeHandler}
                        type={typepaypal1 ? "password" : "text"}
                        readOnly={!isMainAdmin}
                      />
                      <div className="icon-eys-left">
                        <img
                          className={`pointer ${typepaypal1 ? "" : "openeye"
                            }`}
                          onClick={() => changePassType(3)}
                          src={
                            typepaypal1
                              ? "images/eye.png"
                              : "localImages/openeye.svg"
                          }
                          alt="eye"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="payment-methods-container-inner-label">
                      Private key
                    </label>
                    <div className="payment-methods-container-inner">
                      <input
                        value={paypal_pri_key}
                        name="paypal_pri_key"
                        type={typepaypal2 ? "password" : "text"}
                        className="mt-2"
                        onChange={paymentChangeHandler}
                        readOnly={!isMainAdmin}
                      />
                      <div className="icon-eys-left">
                        <img
                          className={`pointer ${typepaypal2 ? "" : "openeye"
                            }`}
                          onClick={() => changePassType(4)}
                          src={
                            typepaypal2
                              ? "images/eye.png"
                              : "localImages/openeye.svg"
                          }
                          alt="eye"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {isMainAdmin && (
                <div className="payment-save-btn">
                  <Button
                    className={
                      "resend-btn w-unset height-45 min-width-130px"
                    }
                    status={0}
                    onClick={() => savePaymentMethods("paypal")}
                    title="Save"
                  />
                </div>
                )}
              </div>
              <div className="mt-5 ml-5">
                <h3>
                  <b>
                    <a
                      href={
                        "https://support.verysoul.com/portal/en/kb/articles/paypal-gateway-integration"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click Here{" "}
                    </a>
                    for instructions for linking your paypal
                  </b>
                </h3>
              </div>
            </div>
            {isMainAdmin && (
              <div class="button-close-proced">
                <button
                  // onClick={() => setTabThree(false)}
                  class="cancell cancel_first_link_zoom"
                >
                  Cancel
                </button>
                <button
                  onClick={clearPaymentField}
                  class="cancell cancel_first_link_zoom"
                >
                  Clear
                </button>
              </div>
            )}
          </div>

        </TabPanel>

        <TabPanel id={5} activeTab={activeTab}>

          <div class="box-popup-inte intepopup_one position-relative">
            <div className="d-flex">
            
                   <a className="export-btn" href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-tracking-data'} >
                   Export Data
        </a> 
              
            </div>
            
          </div>

        </TabPanel>
      </ul>
    </div>
  );
}
