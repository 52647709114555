import React, { useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { innerWidthHandler, innerWidthHandlerstarter } from "../../../../utils/helpers";
import Button from "../../../../components/button";
import { starterVideoWatched } from "../../../../services/client";
import { useDispatch } from "react-redux";
import { setis_starter_video_displayed } from "../../../../reducer/userSlice";

export default function StarterVideoOneTimeWatch({
    onVideoEnd,
    backHandler,

}) {
    const dispatch = useDispatch()
    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    };

    const alreadyWatchedHandler = () => {
        let body = {

            is_starter_video_displayed: 1

        }
        starterVideoWatched(body).then((data) => {
            if (data.data.status) {
                dispatch(setis_starter_video_displayed())
                onVideoEnd()
            }
        })

    }

    return (
        <div>
            <div className="select-appointment">
                <div onClick={backHandler} className="back-btn-appointments pointer">
                    <div className="bck">
                        <img src="images/black-erow.png" alt="back" />
                        <p className="margin-unset"> Back</p>
                    </div>
                </div>

                <div className="container">
                    <div className="select-appointment-inner width-unset">


                        <div className="book-practice">




                            <>
                                <h2>{"Here's a little more about how our system can make things easier for you."}</h2>
                                <Vimeo
                                    muted={isSafari()}
                                    // onEnd={() => onVideoEnd('prof')}
                                    autoplay={true}
                                    className="popover-video"
                                    width={1200}
                                    height={800}
                                    

                                    video={'https://player.vimeo.com/video/1044995911?h=9ead918687&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}

                                />
                                <Button
                                    // onClick={bookStarterSession}
                                    onClick={alreadyWatchedHandler}
                                    className={"practioner-tab-appointment-btn-1"}
                                    title={"I have watched this video"}
                                    status={0}
                                />
                            </>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
