import React from "react";
import { TablePagination } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setGlobalRowsPerPage, setMediumData } from "../../reducer/adminSlice";
import { useSearchParams } from "react-router-dom";

export default function Pagination({
  rowsPerPageOptions,
  count,
  getPaginationData,
  activeTab,
  transactionrowsPerPage,
  transactionPage,
  timezonePage,
  timezonerowsPerPage,

  //
}) {
  const {
    allUpcomingAppointments,
    allCompletedAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,
    allOngoingAppointments,
    allUpcomingCount,
    allCompletedCount,
    allCancelledCount,
    allNoShowCount,
    allOngoingCount,
    upcomingAppointmentsPage,
    upcomingAppointmentsrowsPerPage,
    completedAppointmentsPage,
    completedAppointmentsrowsPerPage,
    cancelledAppointmentsPage,
    cancelledAppointmentsrowsPerPage,
    noshowAppointmentsPage,
    noshowAppointmentsrowsPerPage,
    restrictedAppointmentsPage,
    restrictedAppointmentsrowsPerPage,
    volunteerAppointmentsPage,
    volunteerAppointmentsrowsPerPage,
    endedEarlyAppointmentsPage,
    endedEarlyAppointmentsrowsPerPage,
    ongoingAppointmentsPage,
    ongoingAppointmentsrowsPerPage,
    feedbackrowsPerPage,
    feedbackPage,
    mediumpage,
    mediumrowsPerPage,
    novicepage,
    novicerowsPerPage,
    intermediatePage,
    intermediaterowsPerPage,
    professionalPage,
    professionalrowsPerPage,
    pendingapprovalsPage,
    pendingapprovalsrowsPerPage,
    unverifiedEmailsPage,
    unverifiedEmailsrowsPerPage,
    declinedPage,
    declinedrowsPerPage,

    allPendingApprovalsPage,
    allPendingApprovalsrowsPerPage,
    allUnverifiedEmailsPage,
    allUnverifiedEmailsrowsPerPage,
    allOutreachClientsPage,
    allOutreachClientsrowsPerPage,

    allStarterClientsPage,
    allStarterClientsrowsPerPage,
    allRestrictedClientsPage,
    allRestrictedClientsrowsPerPage,

    allVolunteerClientsPage,
    allVolunteerClientsrowsPerPage,


  } = useSelector((state) => state.users);
  const {
    mediumupcomingAppointmentsPage,
    mediumupcomingAppointmentsrowsPerPage,

    mediumcompletedAppointmentsPage,
    mediumcompletedAppointmentsrowsPerPage,

    mediumcancelledAppointmentsPage,
    mediumcancelledAppointmentsrowsPerPage,

    mediumnoshowAppointmentsPage,
    mediumnoshowAppointmentsrowsPerPage, } = useSelector((state) => state.medium);
  let pagintionTYPE = localStorage.getItem('pagintionTYPE')


  // const dispatch = useDispatch();
  // const [page, setPage] = React.useState(mediumpage);
  // const [rowsPerPage, setRowsPerPage] = React.useState(mediumrowsPerPage);
  // const [startValue, setstartValue] = React.useState(1);
  // const [endValue, setendValue] = React.useState(1);
  // useEffect(() => {
  let pagedata, rowsPerPagedata;
  if (activeTab === "medium") {
    pagedata = mediumpage;
    rowsPerPagedata = mediumrowsPerPage;
  }
  if (activeTab === "novice") {
    pagedata = novicepage;
    rowsPerPagedata = novicerowsPerPage;
  }
  if (activeTab === "intermediate") {
    pagedata = intermediatePage;
    rowsPerPagedata = intermediaterowsPerPage;
  }
  if (activeTab === "professionals") {
    pagedata = professionalPage;
    rowsPerPagedata = professionalrowsPerPage;
  }
  if (activeTab === "pendingAprrovals") {
    pagedata = pendingapprovalsPage;
    rowsPerPagedata = pendingapprovalsrowsPerPage;
  }
  if (activeTab === "unverifiedemails") {
    pagedata = unverifiedEmailsPage;
    rowsPerPagedata = unverifiedEmailsrowsPerPage;
  }
  if (activeTab === "declined") {
    pagedata = declinedPage;
    rowsPerPagedata = declinedrowsPerPage;
  }
  if (activeTab === "upcomingAdmin") {
    pagedata = upcomingAppointmentsPage;
    rowsPerPagedata = upcomingAppointmentsrowsPerPage;
  }
  if (activeTab === "completedAdmin") {
    pagedata = completedAppointmentsPage;
    rowsPerPagedata = completedAppointmentsrowsPerPage;
  }
  if (activeTab === "cancelledAdmin") {
    pagedata = cancelledAppointmentsPage;
    rowsPerPagedata = cancelledAppointmentsrowsPerPage;
  }
  if (activeTab === "noshowsAdmin") {
    pagedata = noshowAppointmentsPage;
    rowsPerPagedata = noshowAppointmentsrowsPerPage;
  }
  if (activeTab === "endedEarlyAdmin") {
    pagedata = endedEarlyAppointmentsPage;
    rowsPerPagedata = endedEarlyAppointmentsrowsPerPage;
  }
  if (activeTab === "restrictedAdmin") {
    pagedata = restrictedAppointmentsPage;
    rowsPerPagedata = restrictedAppointmentsrowsPerPage;
  }
  if (activeTab === "ongoingAdmin") {
    pagedata = ongoingAppointmentsPage;
    rowsPerPagedata = ongoingAppointmentsrowsPerPage;
  }
  if (activeTab === "adminfeedback") {
    pagedata = feedbackPage;
    rowsPerPagedata = feedbackrowsPerPage;
  }
  if (activeTab === "admintransaction") {
    pagedata = transactionPage;
    rowsPerPagedata = transactionrowsPerPage;
  }
  if(activeTab === "admindetectedTimezones"){
    pagedata = timezonePage;
    rowsPerPagedata = timezonerowsPerPage;
  
  }
  //
  if (activeTab === "outreachclient") {
    pagedata = allOutreachClientsPage;
    rowsPerPagedata = allOutreachClientsrowsPerPage;
  }
  if (activeTab === "starterclient") {
    pagedata = allStarterClientsPage;
    rowsPerPagedata = allStarterClientsrowsPerPage;
  }
  if (activeTab === "volunteerclient") {
    pagedata = allVolunteerClientsPage;
    rowsPerPagedata = allVolunteerClientsrowsPerPage;
  }
  if (activeTab === "restrictedclient") {
    pagedata = allRestrictedClientsPage;
    rowsPerPagedata = allRestrictedClientsrowsPerPage;
  }
  if (activeTab === "unverifiedemails") {
    pagedata = allUnverifiedEmailsPage;
    rowsPerPagedata = allUnverifiedEmailsrowsPerPage;
  }
  //


  if (activeTab === "mediumupcoming") {
    pagedata = mediumupcomingAppointmentsPage;
    rowsPerPagedata = mediumupcomingAppointmentsrowsPerPage;
  }
  if (activeTab === "mediumcompleted") {
    pagedata = mediumcompletedAppointmentsPage;
    rowsPerPagedata = mediumcompletedAppointmentsrowsPerPage;
  }
  if (activeTab === "mediumcancelled") {
    pagedata = mediumcancelledAppointmentsPage;
    rowsPerPagedata = mediumcancelledAppointmentsrowsPerPage;
  }
  if (activeTab === "mediumnoshows") {
    pagedata = mediumnoshowAppointmentsPage;
    rowsPerPagedata = mediumnoshowAppointmentsrowsPerPage;
  }

  if (
    activeTab === "upcoming" ||
    activeTab === "completed" ||
    activeTab === "cancelled" ||
    activeTab === "noshows" ||
    activeTab === "ongoing"

  ) {
    pagedata = 1;
    rowsPerPagedata = 10;
  }
  const [page, setPage] = React.useState(pagedata);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPagedata);
  // }, []);

  useEffect(() => {
    // localStorage.removeItem('pagination')
    let data1 = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    // localStorage.setItem('pagination', JSON.stringify(data1))
  }, [])

  useEffect(() => {

    if (activeTab === "mediumupcoming") {
      if (mediumupcomingAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(mediumupcomingAppointmentsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(mediumupcomingAppointmentsPage);
        setRowsPerPage(mediumupcomingAppointmentsrowsPerPage);
      }
    }

  }, [mediumupcomingAppointmentsPage]);

  //

  useEffect(() => {
    console.log(activeTab,"activeTabactiveTabactiveTabactiveTabactiveTabactiveTab")
    if (activeTab === "mediumcompleted") {
      if (mediumcompletedAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(mediumcompletedAppointmentsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(mediumcompletedAppointmentsPage);
        setRowsPerPage(mediumcompletedAppointmentsrowsPerPage);
      }
    }

  }, [mediumcompletedAppointmentsPage]);
  //

  useEffect(() => {
    if (activeTab === "mediumcancelled") {
      if (mediumcancelledAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(mediumcancelledAppointmentsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(mediumcancelledAppointmentsPage);
        setRowsPerPage(mediumcancelledAppointmentsrowsPerPage);
      }
    }

  }, [mediumcancelledAppointmentsPage]);


  useEffect(() => {
    if (activeTab === "mediumnoshows") {
      if (mediumnoshowAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(mediumnoshowAppointmentsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(mediumnoshowAppointmentsPage);
        setRowsPerPage(mediumnoshowAppointmentsrowsPerPage);
      }
    }

  }, [mediumnoshowAppointmentsPage]);

  useEffect(() => {
    if (activeTab === "outreachclient") {
      if (allOutreachClientsPage !== 1) {
        setPage(1);
        setRowsPerPage(allOutreachClientsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(allOutreachClientsPage);
        setRowsPerPage(allOutreachClientsrowsPerPage);
      }
    }

  }, [allOutreachClientsPage]);

  useEffect(() => {
    if (activeTab === "starterclient") {
      if (allStarterClientsPage !== 1) {
        setPage(1);
        setRowsPerPage(allStarterClientsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(allStarterClientsPage);
        setRowsPerPage(allStarterClientsrowsPerPage);
      }
    }
  }, [allStarterClientsPage]);

  useEffect(() => {
    if (activeTab === "volunteerclient") {
      if (allVolunteerClientsPage !== 1) {
        setPage(1);
        setRowsPerPage(allVolunteerClientsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(allVolunteerClientsPage);
        setRowsPerPage(allVolunteerClientsrowsPerPage);
      }
    }
  }, [allVolunteerClientsPage]);

  useEffect(() => {
    if (activeTab === "restrictedclient") {
      if (allRestrictedClientsPage !== 1) {
        setPage(1);
        setRowsPerPage(allRestrictedClientsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(allRestrictedClientsPage);
        setRowsPerPage(allRestrictedClientsrowsPerPage);
      }
    }
  }, [allRestrictedClientsPage]);

  useEffect(() => {
    if (activeTab === "unverifiedemails") {
      if (allUnverifiedEmailsPage !== 1) {
        setPage(1);
        setRowsPerPage(allUnverifiedEmailsrowsPerPage);
    
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(allUnverifiedEmailsPage);
        setRowsPerPage(allUnverifiedEmailsrowsPerPage);
      }
    }
  }
  }, [allUnverifiedEmailsPage]);

  ///

  useEffect(() => {
    if (activeTab === "adminfeedback") {
      if (feedbackPage !== 1) {
        setPage(1);
        setRowsPerPage(feedbackrowsPerPage);
      }

    }
  }, [feedbackPage]);


  useEffect(() => {
    if (activeTab === "admintransaction") {
      console.log('transactionPage', transactionPage)
      if (transactionPage !== 1) {
        setPage(1);
        setRowsPerPage(transactionrowsPerPage);
      }
    }
  }, [transactionPage]);

  useEffect(() => {
    if (activeTab === "admindetectedTimezones") {
      console.log('timezonePage', timezonePage)
      if (timezonePage !== 1) {
        setPage(1);
        setRowsPerPage(timezonerowsPerPage);
      }
    }
  }, [transactionPage]);
  useEffect(() => {
    if (activeTab === "upcomingAdmin") {
      if (upcomingAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(upcomingAppointmentsrowsPerPage);
      }
    }
  }, [upcomingAppointmentsPage]);
  useEffect(() => {
    if (activeTab === "completedAdmin") {
      if (completedAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(completedAppointmentsrowsPerPage);
      }
    }

    // console.log(activeTab,"activeTab")
    // console.log(upcomingAppointmentsPage,"upcomingAppointmentsPage");
    // console.log()
  }, [completedAppointmentsPage]);
  useEffect(() => {
    if (activeTab === "cancelledAdmin") {
      if (cancelledAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(cancelledAppointmentsrowsPerPage);
      }
    }

  }, [cancelledAppointmentsPage]);
  useEffect(() => {
    if (activeTab === "noshowsAdmin") {
      if (noshowAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(noshowAppointmentsrowsPerPage);
      }
    }

  }, [noshowAppointmentsPage]);

  useEffect(() => {
    if (activeTab === "restrictedAdmin") {
      if (restrictedAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(restrictedAppointmentsPage);
      }
    }

  }, [restrictedAppointmentsPage]);

  useEffect(() => {
    if (activeTab === "endedEarlyAdmin") {
      if (endedEarlyAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(endedEarlyAppointmentsPage);
      }
    }

  }, [endedEarlyAppointmentsPage]);
  useEffect(() => {
    if (activeTab === "ongoingAdmin") {
      if (ongoingAppointmentsPage !== 1) {
        setPage(1);
        setRowsPerPage(ongoingAppointmentsrowsPerPage);
      }
    }

  }, [ongoingAppointmentsPage]);

  useEffect(() => {
    let pagintionTYP = localStorage.getItem('pagintionTYPE')
    if (activeTab === "medium") {
      console.log(activeTab, "activeTab")
      console.log(pagintionTYP, "pagintionTYP")
      console.log(mediumpage, "mediumpage")
      if (pagintionTYP == 'change') {

        setPage(mediumpage);
        setRowsPerPage(mediumrowsPerPage);
        setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        return
      }
      if (mediumpage !== 1)
         {
        setPage(1);
        setRowsPerPage(mediumrowsPerPage);
      }


    }
  }, [mediumpage]);
  useEffect(() => {
    if (activeTab === "novice") {

      if (pagintionTYPE == 'change') {
        setPage(novicepage);
        setRowsPerPage(novicerowsPerPage);
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        return;
      }
      else
        if (novicepage !== 1) {
          setPage(1);
          setRowsPerPage(novicerowsPerPage);

        }
    }

  }, [novicepage]);

  useEffect(() => {
    if (activeTab === "intermediate") {
      if (pagintionTYPE == 'change') {
        setPage(intermediatePage);
        setRowsPerPage(intermediaterowsPerPage);
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        return
      } else
        if (intermediatePage !== 1) {
          setPage(1);
          setRowsPerPage(intermediaterowsPerPage);
        }

    }

  }, [intermediatePage]);
  useEffect(() => {
    if (activeTab === "professionals") {
      if (pagintionTYPE == 'change') {
        setPage(professionalPage);
        setRowsPerPage(professionalrowsPerPage);
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        return;
      } else
        if (professionalPage !== 1) {
          setPage(1);
          setRowsPerPage(professionalrowsPerPage);
        }

    }

  }, [professionalPage]);

  useEffect(() => {
    if (activeTab === "pendingAprrovals") {
      if (pendingapprovalsPage !== 1) {
        setPage(1);
        setRowsPerPage(pendingapprovalsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(pendingapprovalsPage);
        setRowsPerPage(pendingapprovalsrowsPerPage);
      }
    }

  }, [pendingapprovalsPage]);

  useEffect(() => {
    if (activeTab === "unverifiedemails") {
      if (unverifiedEmailsPage !== 1) {
        setPage(1);
        setRowsPerPage(unverifiedEmailsrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(unverifiedEmailsPage);
        setRowsPerPage(unverifiedEmailsrowsPerPage);
      }
    }

  }, [unverifiedEmailsPage]);

  useEffect(() => {
    if (activeTab === "declined") {
      if (declinedPage !== 1) {
        setPage(1);
        setRowsPerPage(declinedrowsPerPage);
      }
      if (pagintionTYPE == 'change') {
         setTimeout(() => {
          
          localStorage.removeItem('pagintionTYPE');
        }, 1000);
        setPage(declinedPage);
        setRowsPerPage(declinedrowsPerPage);
      }
    }

  }, [declinedPage]);


  const handleChangePage = (e, number) => {
    setPage(number + 1);
    let data1 = {
      page: number + 1,
      rowsPerPage: rowsPerPage,
    };
    localStorage.setItem('pagination', JSON.stringify(data1))
    getPaginationData(data1);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setGlobalRowsPerPage(parseInt(e.target.value, 10));

    // setGlobalRowsPerPage()

    setPage(1);
    let data2 = {
      page: 1,
      rowsPerPage: parseInt(e.target.value, 10),
    };
    // dispatch(setMediumData(data2));
    getPaginationData(data2);
  };

  const labelDisplayedRows = (item) => {
    return `Showing from ${item.from} to ${item.to} entries of ${count}`;
  };

  // const body={
  //     startValue:startValue,
  //     endValue:endValue
  // }

  // console.log('body',body);

  return (
    <div className="">
      <TablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        page={page - 1}
        //  labelDisplayedRows={labelDisplayedRows}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
