import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { slotsArray } from "./data";

import {
  getMatchedAvailabilites,
  getSlotsByDate,
} from "../../../services/client";
//reducer
import {
  // setBookingTime,
  setmediumListItems,
  setbookingBtnStatus,
  setBookingDate,
  setBookingIds,
  setMediumsToBeMatch,
} from "../../../reducer/clientsSlice";
import { setLoading } from "../../../reducer/auth/auth";
import { removFirstDash, removeString } from "../../../utils/helpers";
import { getTimeImage } from "../../../utils/utils";
import { useParams, useSearchParams } from "react-router-dom";

export default function Slots({
  slots,
  setSlots,
  setBookingTime,
  sloteupdate,
  serviceValue,
  mediumValue,
  bookingTime,
  bookingDate,
  coachingType,
  serviceType,
  month_empty

}) {
  //hooks
  const { id, timezone } = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const preferences = useSelector((state) => state.client);

  const [slotsupdated, setSlotsUpdated] = useState(false);

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (sloteupdate) {

      setSlotsUpdated(true);
      setActive(true);
    }
    else {
      setSlotsUpdated(false);
      setActive(false);
    }
    console.log(slots, "slots")
  }, [sloteupdate]);


  //functions

  const changeStatus = (e, slot) => {
    // if (user_type === undefined) {
    if ((parseInt(serviceType) == 1 && serviceValue === 2) || (parseInt(serviceType) == 1 && serviceValue === 1) ) {
      let { value } = e.target;
      console.log(value, "value")
      setBookingTime(value);
      localStorage.removeItem('grouptimeslot')
      localStorage.setItem('grouptimeslot', JSON.stringify(slots.filter((slot) => parseInt(slot.datetime.time_slot_id) === parseInt(value))))
      setSlots(
        slots.map((slot) => {
          console.log(value, slot)
          if (parseInt(slot.datetime.time_slot_id) === parseInt(value)) {
            return {
              ...slot,
              ...(slot.selectedStatus = true),
              ...(slot.status = true),
            };
          } else {
            return {
              ...slot,
              ...(slot.selectedStatus = false),
              ...(slot.status = false),
            };
          }
        })
      );
      return;
    }
    let { value } = e.target;
    setBookingTime(value);

    setSlots(
      slots.map((slot) => {
        if (slot.datetime === value) {
          return {
            ...slot,
            ...(slot.selectedStatus = true),
            ...(slot.status = true),
          };
        } else {
          return {
            ...slot,
            ...(slot.selectedStatus = false),
            ...(slot.status = false),
          };
        }
      })
    );

  };




  useEffect(() => {
    // setSlots([]);
    // setSlotsUpdated(false);
  }, [timezone]);
  return (
    <>
      {slotsupdated && (
        <>
          {active &&
            React.Children.toArray(
              slots.length > 0 ? slots.map((slot) => ((
                parseInt(serviceType) == 1 && serviceValue === 2) || (parseInt(serviceType) == 1 && serviceValue === 1)) ?
                <div className={`check-field-coaching check-field ${slot.selectedStatus ? "dbb38f-background" : ''}`}>
                  <input
                    className={`${slot.selectedStatus ? "dbb38f-border" : ""}`}
                    onChange={(e) => changeStatus(e, slot)}
                    value={slot.datetime.time_slot_id}
                    checked={slot.status}
                    type="radio"
                    name="check"
                  />
                  <div className="text-center font-size-17 mt-2">
                    <b> {slot.datetime.duration}</b>

                  </div>
                  <label
                    className={`height-unset border-unset ${slot.selectedStatus ? "dbb38f-background" : ""
                      }`}
                    htmlFor="my-checktow"
                  >
                    {/* {moment(slot.date).format("LL")}
            <br /> */}
                    {/* <>
                      {slot.datetime.time + " "}


                    </> */}
                    <>
                      {slot.datetime.from + " "}
                      {getTimeImage(slot.datetime.from) ? (
                        <img
                          src="/localImages/moon.png"
                          className="star-imh-moon"
                          alt="time-image"
                        />
                      )

                        : (
                          ""
                        )}

                    </>
                    &nbsp;
                    to &nbsp; <>
                      {slot.datetime.to + " "}
                      {getTimeImage(slot.datetime.to) ? (
                        <img
                          src="/localImages/moon.png"
                          className="star-imh-moon"
                          alt="time-image"
                        />
                      )

                        : (
                          ""
                        )}

                    </>

                  </label>
                  <br />
                  <div className="text-center p-1">
                    <b> {slot.datetime.label}</b>

                  </div>
                </div>
                :

                <div className={`check-field`}>
                  <input
                    className={`${slot.selectedStatus ? "dbb38f-border" : ""}`}
                    onChange={(e) => changeStatus(e, slot)}
                    value={slot.datetime}
                    checked={slot.status}
                    type="radio"
                    name="check"
                  />
                  <label
                    className={`${slot.selectedStatus ? "dbb38f-background" : ""
                      }`}
                    htmlFor="my-checktow"
                  >
                    {/* {moment(slot.date).format("LL")}
                <br /> */}
                    {slot.datetime + " "}
                    {getTimeImage(slot.datetime) ? (
                      <img
                        src="/localImages/moon.png"
                        className="star-imh-moon"
                        alt="time-image"
                      />
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              )
                :
                <h4 className="mt-5 mb-5"> {'No Availability Found For Selected Date'}</h4>
              // <h4 className="mt-5 mb-5"> {serviceValue === 2 ? 'Please Select Medium To View Slots' : 'No Availability Found For Selected Date'}</h4>
            )}
        </>
      )
      }
      {month_empty ?
        <h4 className="mt-5 mb-5"> {'Sorry no appointments available for this month. Please check future months.'}</h4>
        :
        <>

          {serviceValue === 2 && coachingType == 'paid' && !slotsupdated && <h4 className="mt-5 mb-5"> {'Please select coach to start the booking process.'}</h4>}
          {((serviceValue === 2 && coachingType == 'free') || (serviceValue === 1)) && !slotsupdated && <h4 className="mt-5 mb-5"> {'Please select date and time to start the booking process.'}</h4>}
        </>
      }

    </>
  );
}
