import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMediumPublicProfileDetails } from "../../services/uidata";
import "./embeded.css";

export default function Embeded1() {
    const params = useParams();
    const [personal_booking_links, setpersonal_booking_links] = useState('');
    const [medium_id, setMediumId] = useState('');
    const [mediumName, setMediumName] = useState('')

    useEffect(() => {

        getMediumPublicProfileDetails(params.id).then((data) => {
            console.log(data.data.data)
            if (data.data.status) {
                setpersonal_booking_links(data.data.data.general.personal_booking_link)
                setMediumId(data.data.data.general.medium_id);
                setMediumName(`${data.data.data.preferences.screen_name}`)
            }
        }).catch(() => { })
    }, []);
    return (
        <>
            <div className="headar-saction F8F9FA">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="logo-saction">
                                <a href="javascript:void(0)">
                                    <img src="../images/logo-verysoul.png" alt="LOGO" />
                                </a>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="logo-saction h-100">

                                <div className="h-100 font-size-22 d-flex align-items-center justify-content-center">
                                    Book a Session with {mediumName}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="embeded-wrapper mt-5">
                <div className="embeded-container">
                    <div className="embeded-btn-container">
                        <a
                            target="_blank"
                            className="btn1"
                            href={`${process.env.REACT_APP_BASE_URL}profile-view/${params.id}`}
                        >
                            Recent Reviews
                        </a>

                        <a
                            target={"_blank"}
                            // href={`/register-client?guest=true&id=${medium_id}`}
                            href={`${process.env.REACT_APP_BASE_URL}guest-booking/${medium_id}`}
                            className="btn2"
                        >
                            Book Now
                        </a>
                        {/* <a
                        target={
                           personal_booking_links !== "" && personal_booking_links !== "null" ? "_blank" : "_blank"
                        }
                        href={
                            personal_booking_links !== "" && personal_booking_links !== "null"
                                ?personal_booking_links
                                : "https://app.verysoul.com/register-client"
                        }
                        className="btn2"
                    >
                        Book Now
                    </a> */}
                    </div>
                    <div className="embeded-powered-container">
                        <span>powered by</span>
                        <img src="/localImages/powered.png" alt="" /> <span>VerySoul</span>
                    </div>
                </div>
            </div>
        </>
    );
}
