import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import Select from "react-select";
import "./edit.css";
import {
  getCountry,
  getLanguages,
  getTimezones,
} from "../../../services/uidata";
import { setLoading } from "../../../reducer/auth/auth";
import { getLegalAndServicesForClient, getProfileBasicInfo, updateLegalprivacy } from "../../../services/client";
import {
  updateInitialProfile,
} from "../../../services/profile";
import { capatilizeLetter, filterVisibleTimeZones, getCountryCode } from "../../../utils/helpers";
import { toast } from "react-toastify";
import PhoneInputField from "../../../components/Inputfields/phoneinput";
import { setNewScreenName, setNewTimeZoneData } from "../../../reducer/userSlice";
const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  screen_name: "",
  whatsapp: "",
  country: "",
  languages: [],
  timezone: "",
  agree_to_legal_terms_and_conditionss: 0,
  opt_in_out: '',
};

export default function MyProfile() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formLanguages, setFormLanguages] = useState([]);
  const [newTimezoneData, setNewTimezoneData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [userCountryCode, setUserCountryCode] = useState("US");
  const [TimezoneData, setTimezoneData] = useState([]);
  const [valueUpdated, setVlaueUpdated] = useState(false);

  useEffect(() => {
    // console.log(requestChangeSuceessMessage)
    console.log(userCountryCode);
    // initialValues.timezone = moment.tz.guess();
    getProfile(params.id);
    getLegalData();
    updateCountries();
    updateLanguage();
    // updateTimeZone();
  }, []);
  const updateCountries = () => {
    getCountry()
      .then((data) => {
        setCountryData(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        setFormLanguages(
          data.data.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const updateTimeZone = () => {
    getTimezones()
      .then((data) => {
        if (data.data.status) {

          setTimezoneData(data.data.data);
          setNewTimezoneData(filterVisibleTimeZones(data.data.data));
          dispatch(setLoading(false))
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
   
    if (TimezoneData.length > 0 && newTimezoneData.length>0) {

      checkTimeZone()
    }
  }, [TimezoneData ,newTimezoneData])
  const reloadTimeZones = (refresh) => {
    getTimezones()
      .then((data) => {
        setTimezoneData(data.data.data);
        setNewTimezoneData(filterVisibleTimeZones(data.data.data));
        if (refresh == "refresh") {
          toast.success("TimeZones Refreshed", { position: "top-center" });
        }
      })
      .catch((err) => console.log(err));
  }
  const filterTimeZone = (id) => {
    let zone;
    newTimezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    return zone;
  };
  const RegisterUser = async (formData) => {
    console.log(formData);
    dispatch(setLoading(true));
    const {
      firstName,
      lastName,
      phone,
      whatsapp,
      country,
      languages,
      timezone,
      screen_name,
      agree_to_legal_terms_and_conditionss,
      opt_in_out

    } = formData;

    const elementData = languages.filter(function (element) {
      return element !== undefined;
    });
    console.log(elementData);

    const lang = await elementData.map((item) => {
      return item.id;
    });

    let item = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      whatsapp: whatsapp,
      country: country,
      languages: lang,
      timezone: timezone,
      userId: params.id,
      screen_name: screen_name,
    };
    let item1 = {

      agree_to_legal_terms_and_conditionss: agree_to_legal_terms_and_conditionss ? 1 : 0,
      opt_in_out: opt_in_out === "Yes" ? 1 : 0,


    }
    updateInitialProfile(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Profile Updated", { position: "top-center" });
          getProfile(params.id);
          dispatch(setNewTimeZoneData(filterTimeZone(timezone)));
          dispatch(setNewScreenName(screen_name))
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });

    updateLegalprivacy(item1).then(() => { }).catch(() => { })
  };
  const getProfile = (id) => {
    dispatch(setLoading(true))
    getProfileBasicInfo(id)
      .then((data) => {
        // dispatch(setLoading(false))
        if (data.data.status) {
          updateTimeZone()
          const {
            country,
            first_name,
            last_name,
            screen_name,
            languages,

            phone,

            whatsapp,
            timezone,
          } = data.data.data;

          initialValues.firstName = first_name;
          initialValues.lastName = last_name;
          initialValues.country = country;
          initialValues.phone = phone;
          initialValues.screen_name = screen_name;
          initialValues.languages = updateIncomingHandler(
            languages,
            "languages"
          );

          initialValues.whatsapp = whatsapp;
          initialValues.timezone = timezone.id;


          // setProfileSection(data.data.data);
        }
      })
      .catch((err) => { dispatch(setLoading(false)); console.log(err) });
  };

  const getLegalData = () => {
    getLegalAndServicesForClient().then((data) => {
      console.log(data.data)
      if (data.data.status) {
        setVlaueUpdated(true)
        console.log(data.data.data);
        let info = data.data.data
        initialValues.agree_to_legal_terms_and_conditionss = info.agree_to_legal_terms_and_conditionss === 1 ? true : false;
        initialValues.opt_in_out = info.opt_in_out === 1 ? 'Yes' : info.opt_in_out === 0 ? 'No' : '';
      }
    }).catch(() => { });

  }
  const checkTimeZone = (timezone) => {
    console.log('working' ,initialValues.timezone);
    if (initialValues.timezone !== "" && TimezoneData.length > 0) {
      let time = TimezoneData.filter(
        (item) => item.id === initialValues.timezone
      );
     
      let status = time[0].status;
      let info = time[0];
      let newinfo = newTimezoneData;
      console.log(newinfo)
      if (status === 0) {
        let time = newTimezoneData.filter(
          (item) => item.id === initialValues.timezone
        );
        console.log(newTimezoneData ,newTimezoneData)
        if (!time.length) {
          newinfo.push({ ...info ,status:1});
          setNewTimezoneData(newinfo);
        }
      }
    }
  };
  const updateIncomingHandler = (items, type) => {
    let incomingArray;
    if (type === "languages") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.language_id,
          value: item.language_id,
          label: item.lanugage_name,
        };
      });
    }
    return incomingArray;
  };
  const capatilizeLetters = (e, name, setFieldValue) => {
    let values = e.target.value;
    const input = capatilizeLetter(values);
    setFieldValue(name, input);
  };

  const countryChangeHandler = (e, name, setFieldValue) => {
    let value = e.target.value;
    if (value) {
      setUserCountryCode(getCountryCode(value, countryData));
    }
    setFieldValue(name, value);
  };

  return (
    <>
      <div className="back-btn-profile ml-5 container-fluid">
        <span className="back-handler pointer" onClick={() => navigate(-1)}>
          <img src="../images/arrow-back.png" alt="go back" />
          Go Back
        </span>
      </div>

      <div className="login-register-saction registernew">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-saction-right register-right register-clint">
                <div className="form-l---r">
                  <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                      const errors = {};
                      if (!values.firstName) errors.firstName = "Required";
                      if (!values.lastName) errors.lastName = "Required";
                      // if (values.phone && !/^[0-9]+$/.test(values.phone)) {
                      //   errors.phone = "Invalid Phone";
                      // }
                      if (!values.languages.length)
                        errors.languages = "Required";
                      // if (values.whatsapp && !/^[0-9]+$/.test(values.whatsapp)) {
                      //   errors.whatsapp = "Invalid whatsapp";
                      // }

                      if (
                        values.website &&
                        !/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
                          values.website
                        )
                      ) {
                        errors.website = "Invalid Website";
                      }
                      if (!values.country) errors.country = "Required";
                      if (!values.timezone) errors.timezone = "Required";

                      if (!values.languages) errors.languages = "Required";
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      RegisterUser(values);
                      // setOpen(true);
                      // setTimeout(() => {
                      //   alert(JSON.stringify(values, null, 2));
                      //   setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="from-fild">
                          <div className="icon">
                            <img alt="user" src="/images/user.png" />
                          </div>
                          <label htmlFor="firstName">First Name*</label>
                          <input
                            className={`${errors.firstName &&
                              touched.firstName &&
                              errors.firstName
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={(e) =>
                              capatilizeLetters(e, "firstName", setFieldValue)
                            }
                            onBlur={handleBlur}
                            value={values.firstName}
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                          />
                          <span className="error">
                            {errors.firstName &&
                              touched.firstName &&
                              errors.firstName}
                          </span>
                        </div>

                        <div className="from-fild">
                          <div className="icon">
                            <img alt="user" src="/images/user.png" />
                          </div>
                          <label htmlFor="lastName">Last Name*</label>
                          <input
                            className={`${errors.lastName &&
                              touched.lastName &&
                              errors.lastName
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={(e) =>
                              capatilizeLetters(e, "lastName", setFieldValue)
                            }
                            onBlur={handleBlur}
                            value={values.lastName}
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                          />
                          <span className="error">
                            {errors.lastName &&
                              touched.lastName &&
                              errors.lastName}
                          </span>
                        </div>

                        <div className="from-fild">
                          <div className="icon">
                            <img alt="phone" src="/images/phon.png" />
                          </div>
                          <label htmlFor="phone">Phone</label>
                          <PhoneInputField
                            className={`number-input ${errors.phone && touched.phone && errors.phone
                              ? "error-boundry"
                              : ""
                              }`}
                            // country={userCountryCode.toLowerCase()}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            value={values.phone}
                            id="phone"
                            name="phone"
                          />

                          {/* <input
                          className={`${
                            errors.phone && touched.phone && errors.phone
                              ? "error-boundry"
                              : ""
                          }`}
                          onChange={(e) =>
                            handleChangeHandler(e, "phone", setFieldValue)
                          }
                          onBlur={handleBlur}
                          value={values.phone}
                          type="tel"
                          maxlength="10"
                          pattern="\d{10}"
                          id="phone"
                          name="phone"
                          placeholder="Phone"
                        /> */}
                          <span className="error">
                            {errors.phone && touched.phone && errors.phone}
                          </span>
                        </div>

                        <div className="from-fild">
                          <div className="icon">
                            <img alt="whatsapp" src="/images/whtsp.png" />
                          </div>
                          <label htmlFor="whatsapp">Whatsapp</label>
                          <PhoneInputField
                            className={`${errors.whatsapp &&
                              touched.whatsapp &&
                              errors.whatsapp
                              ? "error-boundry"
                              : ""
                              }`}
                            // country={userCountryCode.toLowerCase()}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            value={values.whatsapp}
                            id="whatsapp"
                            name="whatsapp"
                          />
                          {/* <input
                          className={`${
                            errors.whatsapp &&
                            touched.whatsapp &&
                            errors.whatsapp
                              ? "error-boundry"
                              : ""
                          }`}
                          onChange={(e) =>
                            handleChangeHandler(e, "whatsapp", setFieldValue)
                          }
                          onBlur={handleBlur}
                          maxlength="10"
                          pattern="\d{10}"
                          value={values.whatsapp}
                          type="tel"
                          id="whatsapp"
                          name="whatsapp"
                          placeholder="Whatsapp"
                        /> */}
                          <span className="error">
                            {errors.whatsapp &&
                              touched.whatsapp &&
                              errors.whatsapp}
                          </span>
                        </div>
                        {/* <div className=" mt-2  w-100">
                          <div>
                            {requestChangePhoneNo && (
                              <>
                                <label>
                                  New Phone<i>*</i>
                                </label>
                                <PhoneInputField
                                  className={"w-44"}
                                  country={userCountryCodeChange.toLowerCase()}
                                  onBlur={handleBlur}
                                  value={newPhoneNo}
                                  setValue={setNewPhoneNo}
                                  id="updatedphone"
                                  name="updatedphone"
                                />
                              </>
                            )}
                            <div className="mt-2">
                              <Button
                                status={0}
                                type="button"
                                className={`resend-btn w-unset height-45 min-width-130px`}
                                onClick={requestChangeHandlerPhoneNo}
                                title={
                                  requestChangePhoneNo
                                    ? "Submit"
                                    : "Request Change"
                                }
                              />
                            </div>
                          </div>
                          <div
                            className={`text-left ${
                              requestChangeSuceessMessagePhoneNo ===
                              "New Phone No is required"
                                ? "red"
                                : "green"
                            }`}
                          >
                            {requestChangeSuceessMessagePhoneNo}
                          </div>
                        </div> */}
                        <div className="from-fild">
                          <div className="icon">
                            <img alt="user" src="/images/user.png" />
                          </div>
                          <label htmlFor="firstName">Screen Name*</label>
                          <input
                            className={`${errors.screen_name &&
                              touched.screen_name &&
                              errors.screen_name
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.screen_name}
                            type="text"
                            id="screen_name"
                            name="screen_name"
                            placeholder="Screen Name"
                          />
                          <span className="error">
                            {errors.screen_name &&
                              touched.screen_name &&
                              errors.screen_name}
                          </span>
                        </div>
                        <div className="from-fild">
                          <div className="icon">
                            <img src="/images/australia.png" alt="country" />
                          </div>
                          <label htmlFor="country">
                            Country<i>*</i>
                          </label>
                          <select
                            className={`selectmenu-element form-item__element--select has-placeholder ${errors.country &&
                              touched.country &&
                              errors.country
                              ? "error-boundry"
                              : ""
                              }`}
                            data-name="selectmenu-1"
                            // id="my-selectmenu"
                            onChange={(value) =>
                              countryChangeHandler(
                                value,
                                "country",
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            value={values.country}
                            type="text"
                            id="country"
                            name="country"
                            placeholder="Country"
                          >
                            <option disabled="" selected="" value="">
                              Country<i>*</i>
                            </option>
                            {countryData.map((country) => (
                              <option value={country.country_name}>
                                {/* <span className="d-none">{country.id}</span> */}
                                {country.country_name}
                              </option>
                            ))}
                          </select>
                          {/* <input
                            className={`${
                              errors.country &&
                              touched.country &&
                              errors.country
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.country}
                            type="text"
                            id="country"
                            name="country"
                            placeholder="Country"
                          /> */}
                          <span className="error">
                            {errors.country &&
                              touched.country &&
                              errors.country}
                          </span>
                        </div>
                        {/* <div className="from-fild  w-100">
                          <div className="verification-code-section">
                            <div className="d-flex">
                              {requestChange && (
                                <input
                                  onChange={handleChangeScreenNameHandler}
                                  onBlur={handleBlur}
                                  className="height-45 margin-right-30"
                                  value={newScreenName}
                                  type="text"
                                  id="newScreenName"
                                  name="newScreenName"
                                  placeholder="Screen Name"
                                />
                              )}
                              <Button
                                status={0}
                                type="button"
                                className={`resend-btn w-unset height-45 min-width-130px`}
                                onClick={requestChangeHandler}
                                title={
                                  requestChange ? "Submit" : "Request Change"
                                }
                              />
                            </div>
                            <div
                              className={`text-left ${
                                requestChangeSuceessMessage ===
                                "Screen name is required"
                                  ? "red"
                                  : "green"
                              }`}
                            >
                              {requestChangeSuceessMessage}
                            </div>
                          </div>
                        </div> */}
                        {/* /languages */}

                        <div className="from-fild">
                          <div className="icon">
                            <img src="/images/australia.png" alt="languages" />
                          </div>
                          <label htmlFor="languages">
                            Languages<i>*</i>
                          </label>
                          {/* {values.languages.length > 0 && ( */}
                          <Select
                            id="languages"
                            className="selection-box client-selection-box"
                            options={formLanguages}
                            multi={true}
                            isMulti
                            onChange={(value) =>
                              setFieldValue("languages", value)
                            }
                            onBlur={handleBlur}
                            placeholder="Select languages"
                            value={values.languages}
                            closeMenuOnSelect={false}
                            closeMenuOnScroll={false}
                          />
                          {/* )} */}
                          {/* )} */}
                          {/* <input
                            className={`${
                              errors.languages &&
                              touched.languages &&
                              errors.languages
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.languages}
                            type="text"
                            id="languages"
                            name="languages"
                            placeholder="Languages"
                          /> */}
                          <span className="error">
                            {errors.languages &&
                              touched.languages &&
                              errors.languages}
                          </span>
                        </div>
                        {/* timezone */}
                        <div className="from-fild">
                          <div className="icon">
                            <img src="/images/australia.png" alt="timezone" />
                          </div>
                          <label htmlFor="timezone">
                            Timezone<i>*</i>
                            {/* <img
                              title="Reload TimeZones"
                              onClick={(e) => reloadTimeZones("refresh")}
                              className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                              alt="Reload TimeZones"
                              src="/images/refresh.png"
                            /> */}
                          </label>
                          {/* {timezoneOnly && ( */}
                          <select
                            className={`selectmenu-element form-item__element--select has-placeholder ${errors.timezone &&
                              touched.timezone &&
                              errors.timezone
                              ? "error-boundry"
                              : ""
                              }`}
                            // id="my-selectmenu"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.timezone}
                            type="text"
                            id="timezone"
                            name="timezone"
                            placeholder="Timezone"
                          >
                            <option disabled="" selected="" value="">
                              Timezone<i>*</i>
                            </option>
                            {newTimezoneData.map((timezone) => (
                              <option value={timezone.id}>
                                {/* <span className="d-none">{country.id}</span> */}
                                {timezone.front_name}
                              </option>
                            ))}
                          </select>
                          {/* )} */}
                          {/* <input
                            className={`${
                              errors.languages &&
                              touched.languages &&
                              errors.languages
                                ? "error-boundry"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.languages}
                            type="text"
                            id="languages"
                            name="languages"
                            placeholder="Languages"
                          /> */}
                          <span className="error">
                            {errors.timezone &&
                              touched.timezone &&
                              errors.timezone}
                          </span>
                        </div>

                        {valueUpdated && <>
                          <div className="from-inner-box">
                            <div className="full-fild mt-3">
                              <label>
                                Legal and Privacy <i>*</i>
                              </label>
                              <span className="error marg-top-55">
                                {/* {errors.agree_to_legal_terms_and_conditionss &&
                  touched.agree_to_legal_terms_and_conditionss &&
                  errors.agree_to_legal_terms_and_conditionss} */}
                              </span>
                              <div className="prvacy-box">
                                <input
                                  // onChange={handleChange}
                                  // onBlur={handleBlur}
                                  value="I have read and agree to the terms and conditions"
                                  id="termsandconditions"
                                  type="checkbox"
                                  name="agree_to_legal_terms_and_conditionss"
                                  disabled
                                  checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="termsandconditions" className="width-unset">
                                  I have read and agree to the terms and conditions.
                                </label>
                                <div className="tr-pr">
                                  <Link to="/terms-conditions" target="_blank">
                                    Terms &amp; Conditions
                                  </Link>
                                  <Link to="/privacy-policy" target="_blank">
                                    Privacy Policy
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="from-inner-box radeo check-box-input payment-saction mt-3">
                            <div className="full-fild">
                              <label>
                                Opt-In?? <i>*</i>
                              </label>
                              <h4>
                                I would like to receive notices of offers (for free events,
                                opportunities, etc.)
                              </h4>
                              <div className="check-field">
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value="Yes"
                                  id="opt_in"
                                  type="radio"
                                  name="opt_in_out"
                                  checked={values.opt_in_out === "Yes" ? 'checked' : ''}
                                />
                                {/* {console.log(values.opt_in_out, "values.opt_in_out ")} */}
                                <label htmlFor="optin">Yes</label>
                              </div>
                              <div className="check-field">
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value="No"
                                  id="opt_out"
                                  type="radio"
                                  name="opt_in_out"
                                  checked={values.opt_in_out === "No" ? 'checked' : ''}
                                />
                                <label htmlFor="optout">No</label>
                              </div>
                            </div>
                            <span className="error marg-top-55">
                              {/* {errors.opt_in_out && touched.opt_in_out && errors.opt_in_out} */}
                            </span>
                          </div>
                        </>}
                        <div className="bution">
                          <button type="submit" className="button-bx">
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
