import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

export default function QuestionsStarter({
    onCancelQuestions,
    setRenderType,
    getSessionTerms,
    bookInfo = undefined,
    practitionerInfo,
    url = undefined,
    boo,
    setSaveTerms,
    saveterms,
    bookingType,
    getSubsessions,
    activeSessionId,
    getPractitionarsList,
    activesession_id,
    openBackfillpopup
}) {
    const auth = useSelector((state) => state.user);
    const [error, setError] = useState(false);
    const [checkall, setCheckAll] = useState(false);



    const [check16, setCheck16] = useState(false);
    // const [checkYes, setCheckYes] = useState(false);
    // const [checkNo, setCheckNo] = useState(false);

    useEffect(() => {
        bookingType !== "outreach" && setCheck16(true);
    }, []);
    const changeHandler = (e, setCheck, type) => {
        // if (type === "radio") {
        // setCheckYes(e.target.value);
        // }
        setCheck(e.target.checked);

        // if(check1 && check2 && check3 && check7 && check8 && check9 && check10 && check11 && check12 && check13 && check14){
        //   setCheckAll(e.target.checked);
        // }
        // else{
        //   setCheckAll(e.target.checked);
        // }
        // else if(!check1 || !check2 || !check3 || !check7 || !check8 || !check9 || !check10 || !check11 || !check12 || !check13 || !check14){
        //   setCheckAll(false);
        // }
    };

    const changeHandlerAll = (e) => {
        setCheckAll(e.target.checked);

    };



    // const onClickSaveTerms = () => {
    //     console.log("validate", validator());

    //     if (validator()) {
    //         setError(false);

    //         if (activesession_id === "5" && auth.user.terms_session_status !== 1) {
    //             setSaveTerms(1);
    //             getSessionTerms(1);
    //         }
    //         if (activesession_id === "3" && auth.user.terms_session_starter_status !== 1) {
    //             getSessionTerms(1);
    //         }
    //     } else {
    //         setError(true);
    //     }
    // };

    const onClickHandler = () => {
        if (checkall) {
            setError(false);

            if (activesession_id === "5" && auth.user.terms_session_status !== 1) {
                setSaveTerms(1);
                getSessionTerms(1);
            }
            if (activesession_id === "3" && auth.user.terms_session_starter_status !== 1) {
                getSessionTerms(1);
            }

            console.log(bookingType, "bookingType");
            if (bookingType === "outreach") {
                openBackfillpopup('newappointment');
                // setRenderType("newappointment");
                localStorage.setItem("type", "newappointment");
            } else if (bookingType === "starter") {
                setRenderType("newappointment");
                localStorage.setItem("type", "newappointment");
            }
            else {
                if (bookingType == "professional") {
                    // openBackfillpopup('');
                    getPractitionarsList()
                }
                // getPractitionarsList()
            }
        } else {
            setError(true);
        }
    };

    return (
        <div class="container-fluid question-container">
            {console.log(activesession_id, "activesession_id")}
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset>
                                <div class="form-card">
                                    <div class="row wrap">
                                        <h2 className="text-center">
                                            Please read and confirm each item:
                                        </h2>
                                       
                                        <div class="new margin-unset">
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandlerAll(e);
                                                    }}
                                                    checked={checkall}
                                                    name="checkall"
                                                    type="checkbox"
                                                    id="checkall"
                                                />
                                                <label htmlFor="checkall">Agree To All</label>
                                            </div>
                                        </div>
                                        <h2>I am:</h2>
                                        <div class="new margin-unset">
                                            <div>

                                                <ul>
                                                    <li className="starter-label">
                                                        Do as many free “Starter Client” sessions as I’d like</li>
                                                    <li className="starter-label">Recommend others to be Starter Client volunteers after I have completed 3 sessions</li>
                                                    <li className="starter-label">Get assistance as needed by sending an email to client@verysoul.com</li>
                                                </ul>

                                            </div>

                                            <h2>I will:</h2>
                                            <div >
                                                <ul>
                                                    <li className="starter-label">
                                                        Always attend alone and in a quiet space where I am <span className="text-underline">not distracted</span></li>
                                                    <li className="starter-label">Attend on-time using devices with the Zoom app (no account needed, just the app) </li>
                                                    <li className="starter-label">Respond to potential evidence with “yes”, “no”, “possibly”, or “I don’t know”</li>
                                                    <li className="starter-label">Not record without permission from the Medium (any recordings are for private use only)</li>
                                                    <li className="starter-label">Always complete the <span className="text-underline">feedback surveys promptly</span> after the sessions</li>
                                                    <li className="starter-label">Cancel or reschedule as early as possible whenever I need to do so</li>

                                                </ul>
                                            </div>

                                            {/*****In understabd */}
                                            <h2>I understand that:</h2>
                                            <div >
                                                <ul>
                                                    <li className="starter-label">
                                                        Mediums will be assigned by our system
                                                    </li>
                                                    <li className="starter-label">Mediums in this program are still in development </li>
                                                    <li className="starter-label">Respond to potential evidence with “yes”, “no”, “possibly”, or “I don’t know”</li>
                                                    <li className="starter-label">My privileges may be revoked if I fail to show up, cancel too often, or don’t complete my feedback,</li>
                                                    <li className="starter-label">I am the best interpreter of the information conveyed by my loved ones</li>
                                                    <li className="starter-label">Cancel or reschedule as early as possible whenever I need to do so</li>

                                                </ul>
                                            </div>


                                            {error && (
                                                <div>
                                                    <p className="red">
                                                        *Please Select all check boxes
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <input
                                    onClick={onCancelQuestions}
                                    type="button"
                                    name="previous"
                                    class="previous action-button-previous"
                                    value="Cancel"
                                />
                                <input
                                    onClick={onClickHandler}
                                    type="button"
                                    name="next"
                                    class="next action-button"
                                    value="Next"
                                />
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
