import React from 'react'

export default function Sorting({ callApi, view, types, shortedName, tableName, setSortingNameOrderBy, setSortingTabType, tab, filterName }) {
    const sortTable = (type = 'asc') => {
        let name = {};
        if (tableName === "client" && shortedName === "created_at_order_by") {

            name["created_at_order_by"] = type;

            if (view === 'admin') {
                name["order"] = types;
            }

        } else {
            name["orderBy"] = type;
            if (view === 'admin') {
                name["order"] = types;
            }
        }
        if (setSortingNameOrderBy !== undefined) {
            setSortingTabType(tab)
            setSortingNameOrderBy(name);
        }
        if (filterName !== undefined && filterName == "free_zoom_session") {


            name = {

                orderBy: type,
                oprater: "=",
                field: 'free_zoom_session',
                value: 1,
            }
        }
        callApi(name);
    };
    return (
        <span className="d-inline-block mt-1 sort-box">
            <img onClick={() => sortTable('asc')} className="sort-screenNames sort-screenName d-block pointer" src="/localImages/caret_down.jpeg" alt='sort-icon' />
            <img onClick={() => sortTable('desc')} className="sort-screenNames d-block mt-1 pointer" src="/localImages/caret_down.jpeg" alt='sort-icon' />
        </span>
    )
}
