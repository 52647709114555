import React, { useState } from 'react'
import { useEffect } from 'react'
import { getUserDetectedTimezones } from '../../../services/admin'
import Pagination from '../../../components/tablePagination';
import Checkbox from '../Subcomponents/checkbox';
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment';

export default function UserDetectedTimeZones() {
    const [list, setList] = useState([]);
    const [totalcount, setTotalCount] = useState(0);
    const [timezonePage, settimezonePage] = useState(1);
    const [timezonerowsPerPage, settimezonerowsPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        let info = {
            page: timezonePage, rowsPerPage: timezonerowsPerPage
        }
        getTimes(info);
    }, [])

    const getTimes = (info) => {

        getUserDetectedTimezones(info).then((data) => {
            if (data.data.status) {
                setList(data.data.data.data)
                setTotalCount(data.data.data.total)
                console.log(data.data.data, "data")
            }
        }).catch(() => { })
    }
    const getPaginationData = (data) => {
        let info = {
            page: data.page, rowsPerPage: data.rowsPerPage
        }
        getTimes(info);
    }
    const navigateEdit = (e, id) => {
        window.renderFirst = false;
        if (e.target.className == "capitalizeMedium") {
          navigate(`/edit/${id.user_id}`);
        }
      };
    return (

        <>
            <div className="d-flex">
            <a className="export-btn" href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-user-detected-timezones'} >
                   Export Data 
        </a> 
                {/* <Search callApi={filterListMediums} list={filterList} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues} filterValues={filterValues} isDatepickerShow={1} setOptionsList={setOptionsList}
        optionsList={optionsList}
        operatorList={operatorList}
        setOperatorList={setOperatorList}
        showOprater={showOprater}
        setShowOprater={setShowOprater}
        setFilterOprater={setFilterOprater}
        filterOprater={filterOprater} setFilterValueOptions={setFilterValueOptions} filterValueOptions={filterValueOptions} isOperator={1}/>
      <Filter
        name={"Bulk Actions"}
        list={bulkList}
        changeHandler={changeHandler}
      /> */}

            </div>
            {/* <CsvDownload
      href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-medium-list?user_type=all'}
   
    /> */}

            <div className="table-box-medium">
                <table className="caseTable">
                    <tbody>
                        <tr className="dark">
                            <th >
                                <input
                                    // onChange={(e) => checkBoxChangeHandler(e)}
                                    type="checkbox"
                                    id="one"
                                />
                                <label htmlFor="one"></label>
                                &nbsp;
                            </th>
                            <th>
                                {" "}
                                <div className="d-flex align-items-center">
                                    {" "}
                                    <span className="scren-name">User Name</span>{" "}
                                    {/* <Sorting callApi={filterListMediums} /> */}
                                </div>
                            </th>

                            <th>Email</th>
                            <th>Timezone</th>
                            <th>Timezone Label</th>
                            <th>Matched Status</th>
                            {/* <th>Created At</th> */}

                        </tr>
                        {list.length > 0 ? (
                            list.map((medium) => (
                                <tr
                                    onClick={(e) => navigateEdit(e, medium)}
                                    className="innr pointer"
                                >

                                    <td>
                                        <div className="d-flex-medium-tables">
                                            <Checkbox
                                                medium={medium}
                                                status={false}
                                                selected={undefined}
                                                setSelected={() => { }}
                                            />
                                            <img
                                                src="/images/undraw_profile.svg"
                                                className="profile-image-avatar"
                                                alt="avatar"
                                            />
                                        </div>
                                    </td>
                                    <td className='capitalizeMedium'>{
                                        medium.user_name
                                    }
                                    </td>
                                    <td>
                                        {medium.user_email}
                                    </td>
                                    <td>
                                        {medium.timezone}
                                    </td>
                                    <td>
                                        {medium.timezone_label}
                                    </td>

                                    <td>
                                        {
                                            medium.is_matched == 1 ? 'Matched' : 'Not Matched'
                                        }
                                    </td>
                                    {/* <td>
                                        {moment(medium.created_at).format("YYYY-MM-DD HH:mm")}
                                    </td> */}

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={6} className="text-center">
                                    No Records Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* <div className="pagination-box">
        <div className="left-pagi">
          <span>Showing 1 to 10 of 18 entries</span>
        </div>
        <div className="right-pagi">
          <ul>
            <li>Previous</li>
            <li className="active">
              <a href="#">1</a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>Next</li>
          </ul>
        </div>
      </div> */}

            </div>
            <Pagination
                rowsPerPageOptions={[5, 10, 25]}
                count={totalcount}
                activeTab={"admindetectedTimezones"}
                timezonePage={timezonePage}
                timezonerowsPerPage={timezonerowsPerPage}
                getPaginationData={getPaginationData}
            />
            {/* <div ><PaginatedItems itemsPerPage={5}/></div> */}
        </>
    )
}
