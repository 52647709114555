import { useEffect } from "react";
import moment from "moment";
import { getMettingLink } from "../services/uidata";
import axios from "axios";

const session_id_beg = '2A';
const session_id_end = 'X';

const screenNameHandler = (firstName, lastName) => {
  return `${firstName} ${lastName.substring(0, 1)}`;
};

const formatTime = (item) => {
  return moment(`${item}:00`, ["hh:mm"]).format("hh:mm A");
};

const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};
const capatilizeLetter = (input) => {
  const str = input;

  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(" ");
  return str2;
};
// const capitalizeFirstLetter =(string)=> {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }
const getFirstWordString = (input) => {
  const str = input;
  const arr = str.split(" ");
  console.log(arr);
};
const toTitleCase = (str) => {
  const arr = str.split("_");

  const result = [];

  for (const word of arr) {
    result.push(word.charAt(0).toUpperCase() + word.slice(1));
  }
  return result.join(" ");
};
const getCountryCode = (value, countryData) => {
  let code = countryData.filter((item) => item.country_name === value);
  return code[0].country_code;
};
const innerWidthHandler = () => {
  let innerWidth = window.innerWidth;
  return innerWidth < 768 ? 350 : 660;
};
const innerWidthHandlerstarter = () => {
  let innerWidth = window.innerWidth;
  return innerWidth < 768 ? 600 : 850;
};
const getLink = (id) => {
  getMettingLink(id)
    .then((data) => {
      if (data.data.status) {
        window.open(data.data.data.meeting_link, "_blank");
      }
    })
    .catch(() => { });
};

const copyToClipboard = (url) => {
  navigator.clipboard.writeText(url);
};
const removeString = (value) => {
  return value !== "" ? value.substring(0, value.lastIndexOf(":")) : "";
};

const convertToUtc = (date, time) => {
  return moment(`${date} ${time}`, "YYYY-MM-DD kk:mm")
    .utc()
    .format("kk:mm:ss");
};
const convertToLocal = (date, time) => {
  console.log(`${date} ${time}`, "date,time");
  let resp = moment
    .utc(`${date} ${time}`, "YYYY-MM-DD HH:mm")
    .local()
    .format("HH:mm");
  console.log(resp);
  return resp;
};

const filterVisibleTimeZones = (timezoneData) => {
  let times = timezoneData.filter((item) => {
    if (item.status === 1) {
      return {
        ...item,
      };
    }
  });
  let newTimes = times.map((item) => {
    return {
      ...item,
      value: item.id,
      label: `${item.front_name}`,
    };
  });
  return newTimes;
};
const filterZone = (timezone, timezoneData) => {
  console.log(timezone, timezoneData, "timezone, timezoneData")
  let item = timezoneData.filter((time) => time.name === timezone.front_name);
  return item[0].id;
};
const removFirstDash = (str) => {
  let indexat0 = str.charAt(0)
  if (indexat0 === "-") {
    return str.replace("-", "")
  }
  else {
    return str
  }

}
const toolbar = {
  options: [
    "blockType",
    "inline",
    "list",
    // "textAlign",
    "link",
    // "embedded",
    "image"
  ],
  blockType: {
    inDropdown: true,
    options: ["H2", "H3", "H4", "Normal", "Blockquote"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline"]
  },
  link: {
    options: ["link", "unlink"],
    showOpenOptionOnHover: false
  },
  list: {
    options: ["ordered", "unordered"]
  },
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
    alt: { present: true, mandatory: true },
    defaultSize: {
      height: "auto",
      width: "auto"
    }
  }
};
const localization = {
  locale: "en-us",
  translations: {
    "generic.add": "Add",
    "generic.cancel": "Cancel",

    "components.controls.blocktype.normal": "Normal",
    "components.controls.blocktype.h2": "Heading 1",
    "components.controls.blocktype.h3": "Heading 2",
    "components.controls.blocktype.h4": "Heading 3",
    "components.controls.blocktype.blockquote": "Blockquote",

    "components.controls.embedded.embedded": "Embedded",
    "components.controls.embedded.embeddedlink": "Embedded Link",
    "components.controls.embedded.enterlink": "Enter link",

    "components.controls.link.linkTitle": "Link Title",
    "components.controls.link.linkTarget": "Link Target",
    "components.controls.link.linkTargetOption": "Open link in new window",
    "components.controls.link.link": "Link",
    "components.controls.link.unlink": "Unlink",

    "components.controls.image.image": "Image",
    "components.controls.image.fileUpload": "File Upload",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Drop the file or click to upload"
  }
};
const useOutsideAlerter = (ref, state) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log(state, "state");
        state(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const emptyAllSelectBox = () => {
  let doc = document.getElementsByClassName('appointment-select-box');
  let alo = Array.prototype.map.call(doc, (info) => { return info })
  alo.map((info) => {
    info.value = ""
  })
}

const getBrowserData = async () => {

  // const res = await axios.get("https://ipinfo.io");
  var browserName = navigator.appName;
  // res.data.ip;
  let ip = ""
  let OSName = "Unknown OS";;

  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = '' + parseFloat(navigator.appVersion);
  var nameOffset, verOffset;
  let body;
  if (window.navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
  if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "MacOS";
  if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
  if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
  if (window.navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
  if (window.navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
  if ((verOffset = nAgt.indexOf("OPR")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 4);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.substring(verOffset + 4);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent 
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  body = {

    ip_address: ip,
    browser_info: browserName,
    os_info: OSName,

  }
  return body;

};

const removeQueryParams = () => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.delete("view");
  newUrl.searchParams.delete("search");
  newUrl.searchParams.delete("searchName");
  newUrl.searchParams.delete("page");
  newUrl.searchParams.delete("rowperpage");
  newUrl.searchParams.delete("searchOprator");
  newUrl.searchParams.delete("searchFilterOprater");
  window.history.replaceState({}, document.title, newUrl.href);

};
const setQueryParams = (setQueryValue, setQuery1, setQuery2, setQuery3, setQuery4,setQuery5,setQuery6, status, searchVal, searchName, page, rowperpage,searchOprator,searchFilterOprater) => {
  setQueryValue(status !== null ? status : '')
  setQuery1(searchVal !== null ? searchVal : '');
  setQuery2(searchName !== null ? searchName : '')
  setQuery3(page !== null ? page : '')
  setQuery4(rowperpage !== null ? rowperpage : '')
  setQuery5(searchOprator !== null ? searchOprator : '')
  setQuery6(searchFilterOprater !== null ? searchFilterOprater : '')
}
export {
  session_id_beg,
  session_id_end,
  useOutsideAlerter,
  screenNameHandler,
  removeItemOnce,
  capatilizeLetter,
  getFirstWordString,
  toTitleCase,
  getCountryCode,
  innerWidthHandler,
  getLink,
  copyToClipboard,
  removeString,
  convertToUtc,
  convertToLocal,
  formatTime,
  filterVisibleTimeZones,
  filterZone,
  removFirstDash,
  toolbar,
  localization,
  emptyAllSelectBox,
  getBrowserData,
  removeQueryParams,
  setQueryParams,
  innerWidthHandlerstarter
  // capitalizeFirstLetter
};
