import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './overviewmedium.css';
import { copyToClipboard } from '../../utils/helpers';

export default function () {
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [toggles, setToggles] = useState({
    toggle1: true,
    toggle2: true,
    toggle3: false,
    toggle4: true,
    toggle5: true,
    toggle6: true,
    toggle7: true,
    toggle8: false,
    toggle9: false,
    toggle10: false,
    toggle11: false,
    toggle12: false,
    toggle13: false,
    toggle14: false,
    toggle15: false,
    toggle16: false,
    toggle17: false,
    toggle18: false,
    toggle19: false,
    toggle20: false,
    toggle21: false,
    toggle22: false,
    toggle23: false,
    toggle24: false,
    toggle25: false,
    toggle26: false,
    toggle27: false,
    toggle28: false,


  });

  const handleToggle = (toggleName) => {
    setToggles((prev) => ({
      ...prev,
      [toggleName]: !prev[toggleName],
    }));
  };
  return (
    <>
      <div className="header-saction">
        <div className="container">
          {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#"><img src="images/Very Soul Logo Black.png" alt="LOGO" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse show" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active"><a className="nav-link" href="#">How it Works</a></li>
                <li className="nav-item"><a className="nav-link" target='_blank' href="https://verysoul.com/about-us">Our Story</a></li>
                <li className="nav-item"><a className="nav-link" target='_blank' href="https://verysoul.com/members-map">Global Presence</a></li>
                <li className="nav-item"><a className="nav-link" target='_blank' href="https://verysoul.com/reviews">Review</a></li>

                <li className="nav-item">
                  <Link to={""} data-toggle="modal"
                    data-target="#myModal"
                    className="sign-up">Sign Up</Link>

                
                </li>
                <li className="nav-item">
                  <Link to={"/login"} className='login'>Login</Link>
                 
                </li>
              </ul>
            </div>
          </nav> */}
        </div>
      </div>
      {/* <div className="banner-saction1">
        
          <img className='w-100' src="images/Mask-Group-20-1.jpg" alt='banner' />
        
      </div> */}
      <div className="py-5 offerings-container">

        <h2 className="section-title">Overview of Offerings for Mediums</h2>
        <div className="row ">
          {/* Practice with Mediums */}
          <div className="col-md-6 col-sm-12  col-lg-4 mainCard">
            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle3 ? 'height-80-px overflow-hidden' : ''}`}>
              <div className={`card-header offerings-header ${toggles.toggle3 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle3')}>Manage Your Day</div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">

                  <ul className={`offerings-list`}>
                    <li className='sub-list-item-last uppercase'><span className="list-icon"></span><Link to={'/appointments'} className='anchore-tags'> See Upcoming Appointments </Link></li>
                    <li className='sub-list-item-last uppercase'><span className="list-icon"></span><Link to={'/go-on-demand-practice?to=ondemand'} className='anchore-tags'>  Do a Last Minute Practice</Link></li>
                    <li className='sub-list-item-last uppercase'><span className="list-icon"></span><Link to={'/go-on-demand-practice'} className='anchore-tags'>  Attend a Group Event</Link></li>
                    <li className='sub-list-item-last uppercase disabled-date gray'><span className="list-icon "></span><a href={'#'} className='anchore-tags '>  Go Offline for an Emergency (TBD)</a></li>
                  </ul>

                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>
            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle1 ? 'height-80-px overflow-hidden' : ''}`}>

              <div className={`card-header offerings-header ${toggles.toggle1 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle1')}>Practice with Mediums  </div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">
                  <li onClick={() => handleToggle('toggle8')} className={`mb-2 d-flex align-items-center justify-content-between ${toggles.toggle8 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">PRACTICE 1-ON-1</strong> </li>
                  <ul className={`offerings-list ${toggles.toggle8 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li><span className="list-icon"></span> <Link to={'/go-on-demand-practice?to=ondemand'}>Last Minute (Match & Go)</Link></li>
                    <ul className="sub-list"><li className="sub-list-item sub-list-item-last disabled-date gray">Be An "Extra" Sitter (TBD)</li></ul>
                    <li><span className="list-icon"></span> Scheduled in Advance</li>
                    <ul className="sub-list">
                      <li className="sub-list-item"><Link to={'/booking?to=bookwithother'} >Book with Another Medium</Link></li>
                      <li className="sub-list-item  sub-list-item-last"><Link to={'/availability'}>Take Bookings From Mediums</Link> </li>
                    </ul>
                    <li><span className="list-icon"></span> Related Skills Practices</li>
                    <ul className="sub-list"><li className="sub-list-item  sub-list-item-last disabled-date gray">Psychic Practices (TBD)</li></ul>
                  </ul>
                  <li onClick={() => handleToggle('toggle9')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle9 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}

                  ><strong className="offerings-subtitle">PRACTICE IN/ON A GROUP</strong></li>
                  <ul className={`offerings-list ${toggles.toggle9 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className="sub-list-item"><Link to={'/go-on-demand-practice?to=practicecircles'} > Practice Circles</Link></li>
                    {/* <li className='sub-list-item-last'> <Link to={'/booking?to=coachedgroupcoaching'} > Coached Practice Circles</Link></li> */}
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Coached Practice Circles (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Platform/Demonstration (1-3 work) (TBD)</li>
                  </ul>
                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>
            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle6 ? 'height-80-px overflow-hidden' : ''}`}>
              <div className={`card-header offerings-header ${toggles.toggle6 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle6')}>Work With Clients</div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">
                  <li onClick={() => handleToggle('toggle10')} className={`mb-2 d-flex align-items-center justify-content-between ${toggles.toggle10 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">CONDUCT SESSIONS</strong></li>
                  <ul className={`offerings-list ${toggles.toggle10 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span><Link to={'/appointments'}>Scheduled Sessions</Link> </li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> On Demand Sessions (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Platform/Demonstrations (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span><Link to={'/backfill'}>Manage Backfill Options (TBD)</Link> </li>
                  </ul>
                  <li onClick={() => handleToggle('toggle11')} className={`mb-2 d-flex align-items-center justify-content-between ${toggles.toggle11 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">GUIDANCE BY PROGRAM</strong></li>
                  <ul className={`offerings-list ${toggles.toggle11 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Practice Program (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Starter Client Program (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Outreach Client Program (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Professional Program (TBD)</li>
                  </ul>
                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>



          </div>

          {/* Learn About VerySoul */}

          <div className="col-md-6 col-sm-12 col-lg-4 mainCard">




            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle5 ? 'height-80-px overflow-hidden' : ''}`}>
              <div className={`card-header offerings-header ${toggles.toggle5 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle5')}>Give & Get Support</div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">
                  <li onClick={() => handleToggle('toggle12')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle12 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">GET COACHED BY A PRO</strong></li>
                  <ul className={`offerings-list ${toggles.toggle12 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span><Link to={'/booking?to=coaching1on1'}>1-on-1 (one free every 6 months)</Link> </li>
                    <li className='sub-list-item-last'><span className="list-icon"></span><Link to={'/go-on-demand-practice?to=groupcoaching'}> Small Groups</Link></li>

                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Practice Circles with Coaching (TBD)</li>

                  </ul>
                  <li onClick={() => handleToggle('toggle13')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle13 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">ATTEND SPECIAL EVENTS</strong></li>
                  <ul className={`offerings-list ${toggles.toggle13 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Q&A Sessions (TBD)</li>
                    <li className='sub-list-item-last'><span className="list-icon "></span><Link to={'/go-on-demand-practice?to=speakerEvents'}> Presentations</Link> </li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Professional Demonstrations (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Recorded Sessions (TBD)</li>

                  </ul>
                  <li onClick={() => handleToggle('toggle14')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle14 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">TALK WITH OTHER MEDIUMS</strong></li>
                  <ul className={`offerings-list ${toggles.toggle14 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Affinity Groups (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Support Groups (TBD)</li>

                  </ul>
                  <li onClick={() => handleToggle('toggle15')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle15 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">GET HELP WITH THE SYSTEM</strong></li>
                  <ul className={`offerings-list ${toggles.toggle15 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span>  Attend a VS Info. Session (TBD)</li>
                    <li className='sub-list-item-last'><span className="list-icon"></span><a href="mailto:Support@verysoul.com" onClick={() => copyToClipboard('Support@verysoul.com')} > Email Support</a></li>
                    <li className='sub-list-item-last'><span className="list-icon"></span><Link to={'/booking?to=supportteam'}>Schedule a Help Appointment</Link> </li>

                  </ul>
                  <li onClick={() => handleToggle('toggle16')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle16 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">CONSIDER OUTSIDE TEACHERS</strong></li>
                  <ul className={`offerings-list ${toggles.toggle16 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span>  Share Your Favorites (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Insights From Peers (TBD)</li>
                  </ul>
                  <li onClick={() => handleToggle('toggle17')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle17 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle"> BOOK A PRO MEDIUMSHIP SESSION</strong></li>
                  <ul className={`offerings-list ${toggles.toggle17 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span><Link to={'/booking?to=professionalsessions'}> Book Here</Link></li>

                  </ul>
                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>
            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle2 ? 'height-80-px overflow-hidden' : ''}`}>
              <div className={`card-header offerings-header ${toggles.toggle2 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle2')}>Manage Your Career</div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">
                  <li onClick={() => handleToggle('toggle18')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle18 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">OPTIMIZE YOUR FEEDBACK</strong></li>
                  <ul className={`offerings-list ${toggles.toggle18 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span> <Link to={'/feedbacks'}>Review your Feedback</Link></li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> How to Give & Receive Feedback (TBD)</li>

                  </ul>
                  <li onClick={() => handleToggle('toggle19')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle19 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">REVIEW YOUR PROGRESS</strong></li>
                  <ul className={`offerings-list ${toggles.toggle19 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Overview Dashboard (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span><a target='_blank' href='https://formfacade.com/public/107057487601614273004/all/form/1FAIpQLSfTwin7OfGF3KfX3KRFn5vM2i_he6147o3lodTzPB4FyhFMng'>Competency Model</a> </li>

                  </ul>
                  <li onClick={() => handleToggle('toggle20')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle20 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">RECOVER FROM A SETBACK</strong></li>
                  <ul className={`offerings-list ${toggles.toggle20 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span><Link to={'/booking?to=findacoach'}>Find a Coach</Link> </li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Stepping Back (TBD)</li>

                  </ul>
                  <li onClick={() => handleToggle('toggle21')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle21 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle disabled-date gray">UPDATE READINESS EVALUATION (TBD)</strong></li>
                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>
          </div>

          {/* Work With Clients */}
          <div className="col-md-6 col-sm-12 col-lg-4  mainCard">

            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle7 ? 'height-80-px overflow-hidden' : ''}`}>
              <div className={`card-header offerings-header ${toggles.toggle7 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle7')}>Support VerySoul</div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">
                  <li onClick={() => handleToggle('toggle22')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle22 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">FOLLOW ON SOCIAL MEDIA</strong></li>
                  <ul className={`offerings-list ${toggles.toggle22 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span><a href='https://www.facebook.com/VerySoulApp1' target='_blank'> Facebook</a></li>
                    <li className='sub-list-item-last'><span className="list-icon"></span> <a href='https://www.instagram.com/verysoulapp/' target='_blank'>Instagram</a></li>

                  </ul>
                  <li onClick={() => handleToggle('toggle23')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle23 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">REFER OTHERS</strong></li>
                  <ul className={`offerings-list ${toggles.toggle23 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span><Link to={'/refer-others'}> Starter Client Volunteers</Link></li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Mediums (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Translators (TBD)</li>


                  </ul>
                  <li onClick={() => handleToggle('toggle24')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle24 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">VOLUNTEER WITH VERYSOUL</strong></li>
                  <ul className={`offerings-list ${toggles.toggle24 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> As "Extra" For Last Minute Practices (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> As Facilitator (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> As Program Buddy (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> As Coach (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> As Translator (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> As Potential Speaker (TBD)</li>

                  </ul>
                  <li onClick={() => handleToggle('toggle25')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle25 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">LEVERAGE CONNECTIONS </strong></li>
                  <ul className={`offerings-list ${toggles.toggle25 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span>  Refer to Podcasters/Media (TBD)</li>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Refer to Potential Sponsors (TBD)</li>


                  </ul>
                  <li onClick={() => handleToggle('toggle26')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle26 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">HELP FUND
                    </strong></li>
                  <ul className={`offerings-list ${toggles.toggle26 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last'><span className="list-icon"></span> <a target='_blank' href={'https://verysoul.com/pay-it-forward/'}>Contribute $s</a></li>

                  </ul>

                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>
            <div className={`card offerings-card border-0 p-3 h-100 ${toggles.toggle4 ? 'height-80-px overflow-hidden' : ''}`}>
              <div className={`card-header offerings-header ${toggles.toggle4 ? 'offerings-header-up' : 'offerings-header-up '}`} onClick={() => handleToggle('toggle4')}>Learn About VerySoul</div>
              <div className="card-body">
                <ul className="list-unstyled mt-3">
                  <li onClick={() => handleToggle('toggle27')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle27 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">BACKGROUND</strong></li>
                  <ul className={`offerings-list ${toggles.toggle27 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Overview of Medium Programs (TBD)</li>
                    <li className='sub-list-item-last'><span className="list-icon"></span><a target='_blank' href={'https://verysoul.com/operating-principle/'}> Operating Principles</a></li>
                    <li className='sub-list-item-last'><span className="list-icon"></span><a target='_blank' href={'https://verysoul.com/code-of-ethics/'}> Code of Ethics</a></li>
                    <li className='sub-list-item-last'><span className="list-icon"></span><a target='_blank' href={'https://verysoul.com/medium-faqs/'}>Medium Q&A’s</a></li>
                  </ul>
                  <li onClick={() => handleToggle('toggle28')} className={`mb-2 d-flex align-items-center justify-content-between 
                    ${toggles.toggle28 ? 'offerings-header-inner' : 'offerings-header-up-inner '}`}><strong className="offerings-subtitle">MEDIA</strong></li>
                  <ul className={`offerings-list ${toggles.toggle28 ? 'height-0-px overflow-hidden' : ''}`}>
                    <li className='sub-list-item-last disabled-date gray'><span className="list-icon "></span> Podcasts (TBD)</li>
                  </ul>
                </ul>
              </div>
              <div className="corner-fold"></div>
            </div>


            {/* come here new */}
          </div>







        </div>
        <div className='footer-saction-home'>
          <div className="container">
            <div className='footer-logo-home'><img src="images/Union-icon.png" /> </div>
            <p>For greater perspective on life</p>
            <ul>
              <li>
                <Link target='_blank' to={'/terms-conditions'}>Terms & Conditions</Link>
                {/* <a href='#'>Terms& Conditions</a> */}
              </li>
              <li>|</li>
              <li>
                <Link target='_blank' to={'/privacy-policy'}>Privacy Policy</Link>
                {/* <a href='#'>Privacy Policy</a> */}
              </li>
            </ul>
            {/* <span><b>Note: For optimum performance: use Chrome, Safari, or Edge</b></span> */}
            <span>@2023 very Soul LLC. All right reserved.</span>
          </div>
        </div>
      </div>
    </>
  );
}
