import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../components/button";
import { logout, updateZoomVerificationCod } from "../../../../services/auth";
import TickMark from "../../../../components/tickmark";
import AudioZoom from "./audio.mp3";
import VolumeBtn from "./stop-y.png";
import VolumeMute from "./play-y.png";
import { saveZoomSession } from "../../../../services/client";

export default function DonatedBookingQuestion({
    onCancelQuestions,
    startLoaderNow,
    saveUserTracksHandler,
}) {
    const audioRef = useRef(null);

    const [error, setError] = useState(false);

    const [check4, setCheck4] = useState(false);
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [checkYes, setCheckYes] = useState(false);
    const [checkYes1, setCheckYes1] = useState(false);
    const [checkYespopup, setCheckYespopup] = useState("");
    const [checkYespopupError, setCheckYespopupError] = useState(false);
    const [zoomverificationCode, setZoomVerificationCode] = useState("");
    const [activated, setActivated] = useState(false);
    const [zoom_code_yes_no, setZoom_code_yes_no] = useState("");
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [confirmcheck1, setconformcheck1] = useState(false);
    const [confirmcheckCantDownload, setconfirmcheckCantDownload] = useState(
        false
    );
    const [confirmcheck2, setconformcheck2] = useState(false);
    const [zoomError, setZoomError] = useState(false);
    const [mute, setMute] = useState(false);

    const logoutUser = () => {
        let token = localStorage.getItem("access_token");
        logout(token)
            .then((data) => {
                if (data.data.status) {
                    window.location.reload();
                }
            })
            .catch((err) => console.log(err));
    };
    const changeHandler = (e, setCheck, type) => {
        if (type === "radio") {
            if (e.target.value == "no") {
                window.loadModal2("#myModal2-zoom", "show");
                setCheckYes1("");
            }
            setCheckYes(e.target.value);
            let body = {
                session_id: localStorage.getItem("session_id"),
                zoom_question1_option: e.target.value == "yes" ? "yes" : "no",
            };
            saveUserTracksHandler(body);
        } else {
            setCheck(e.target.checked);
        }
    };
    const changeHandler1 = (e, setCheck, type) => {
        if (type === "radio") {
            setCheckYes1(e.target.value);
            let body = {
                session_id: localStorage.getItem("session_id"),
                zoom_question2_option: e.target.value == "yes" ? "yes" : "no",
            };
            saveUserTracksHandler(body);
        } else {
            setCheck(e.target.checked);
        }
    };
    const changeHandlerpopup = (e, setCheck, type) => {
        console.log(e.target.value);
        if (type === "radio") {
            setCheckYespopup(e.target.value);
        } else {
            setCheckYespopup(e.target.checked);
        }
    };
    const onClickHandler2 = () => {
        if (
            (checkYes === "yes" &&
                checkYes1 === "yes" &&
                zoomverificationCode == "5630") ||
            (checkYes === "yes" &&
                checkYes1 === "no" &&
                zoomverificationCode == "5630" &&
                confirmcheck1 &&
                confirmcheck2)
        ) {
            callBackFunc4();
            // setStep(1);
            setError(false);
            startLoaderNow();
        } else {
            if (zoomverificationCode !== "5630") {
                setZoomError(true);
            }
            setError(true);
        }
    };

    const onChangesetZoomVerificationCode = (e) => {
        let value = e;
        if (value !== "5630") {
            setZoomError(true);
            setActivated(true);
        } else {
            setActivated(false);
            setZoomError(false);
        }
        setZoomVerificationCode(value);
        if (value === "5630") {
            callBackFunc2();
            updateZoomVerificationCod()
                .then(() => { })
                .catch(() => { });
        }
    };
    const handleChangeoyp = (index, value) => {
        if (!/^[0-9]?$/.test(value)) return; // Allow only numbers

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to next input if a number is entered
        if (value && index < 3) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    const handleKeyDownotp = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    useEffect(() => {
        let string = otp.join("");
        console.log(string);
        if (string.length == 4) {
            onChangesetZoomVerificationCode(string);
        } else {
            setActivated(true);
            setZoomVerificationCode(string);
        }
        // onChangesetZoomVerificationCode
    }, [otp]);
    const popUpHandler = () => {
        if (checkYespopup == "") {
            setCheckYespopupError(true);
            return;
        } else {
            setCheckYespopupError(false);
        }
        if (checkYespopup == "yes") {
            setCheckYes("yes");
            window.loadModal2("#myModal2-zoom", "hide");
        } else {
            logoutUser();
        }
    };
    const onchangeCandDownloadHandler = (e) => {
        console.log(e.target.checked, "e.target.checked");
        setconfirmcheckCantDownload(e.target.checked);
        if (e.target.checked) {
            window.loadModal2("#myModal2-zoom-free-session", "show");
        }
    };
    const putMeOnWitlistHandler = () => {
        console.log("putMeOnWitlistHandler");
        let body = {
            zoom_free_session_waitlist: "no",
        };
        saveZoomSession(body)
            .then(() => {
                window.loadModal2("#myModal2-zoom-free-session", "hide");
                setconformcheck1(false);
                setTimeout(() => {
                    window.loadModal2("#myModal2-zoom-free-session-thanks", "show");
                }, 100);

                setTimeout(() => {
                    logoutUser();
                }, 1000);
            })
            .catch(() => { });
    };

    const playAudio = () => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_audio_clicked: "yes",
        };
        saveUserTracksHandler(body);
        if (audioRef.current) {
            // audioRef.current.currentTime = 0; // Restart audio
            audioRef.current.play();
        }
    };
    const pauseAudio = () => {
        if (audioRef.current) {
            // audioRef.current.currentTime = 0; // Restart audio
            audioRef.current.pause();
        }
    };

    // useEffect(() => {
    //     if (checkYes1 == "no") {
    //          audioRef.current.currentTime = 0;
    //         playAudio()
    //     } else {
    //         pauseAudio();
    //     }
    // }, [checkYes1])

    const muteAudioHandler = () => {
        setMute(!mute);
    };

    useEffect(() => {
        if (mute) {
            playAudio();
        } else {
            pauseAudio();
        }
    }, [mute]);

    const callBackFunc = (type) => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_app_download_option: type,
        };
        saveUserTracksHandler(body);
    };

    const callBackFunc1 = (type) => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_link_clicked: "yes",
        };
        saveUserTracksHandler(body);
    };

    const callBackFunc2 = (type) => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_otp_entered: "yes",
        };
        saveUserTracksHandler(body);
    };
    const callBackFunc3 = (type) => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_audio_video_tested: "yes",
        };
        saveUserTracksHandler(body);
    };
    const callBackFunc4 = () => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            next_btn_clicked: "yes",
        };
        saveUserTracksHandler(body);
    };
    const callBackFunc5 = () => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            cancel_btn_clicked: "yes",
        };
        saveUserTracksHandler(body);
        setTimeout(() => {
            onCancelQuestions();
        }, 100);
    };
    const callBackFunc6 = () => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_app_download_link_clicked: "yes",
        };
        saveUserTracksHandler(body);
    };
    const callBackFunc7 = () => {
        let body = {
            session_id: localStorage.getItem("session_id"),
            zoom_test_link_clicked: "yes",
        };
        saveUserTracksHandler(body);
    };
    return (
        <>
            <div class="container-fluid question-container">
                <div class="row justify-content-center">
                    <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                        <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                            <form id="msform">
                                <fieldset>
                                    <div class="form-card">
                                        <div class="row wrap">
                                            <h2>
                                                All VerySoul sessions are conducted online using Zoom
                                                video technology.
                                                <br />
                                                Let’s be sure you’re ready to use Zoom before you sign
                                                up for your session.
                                            </h2>
                                            <h3>
                                                Is this the device you plan to use for your session?
                                            </h3>
                                            <div class="new secend-step margin-unset d-flex">
                                                <div class="form-group">
                                                    <input
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheckYes, "radio");
                                                        }}
                                                        value={"yes"}
                                                        checked={checkYes === "yes"}
                                                        name="yesNo"
                                                        type="radio"
                                                        id="yes"
                                                    />
                                                    <label htmlFor="yes">Yes</label>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        checked={checkYes === "no"}
                                                        onChange={(e) => {
                                                            changeHandler(e, setCheckYes, "radio");
                                                        }}
                                                        value={"no"}
                                                        name="no"
                                                        type="radio"
                                                        id="no"
                                                    />
                                                    <label htmlFor="no">No</label>
                                                </div>
                                            </div>

                                            {checkYes == "yes" && (
                                                <>
                                                    <h3>Have you used Zoom recently on this device?</h3>
                                                    <div class="new secend-step margin-unset d-flex">
                                                        <div class="form-group">
                                                            <input
                                                                onChange={(e) => {
                                                                    changeHandler1(e, setCheckYes1, "radio");
                                                                }}
                                                                value={"yes"}
                                                                checked={checkYes1 === "yes"}
                                                                name="yesNo1"
                                                                type="radio"
                                                                id="yes1"
                                                            />
                                                            <label htmlFor="yes1">Yes</label>
                                                        </div>
                                                        <div class="form-group">
                                                            <input
                                                                checked={checkYes1 === "no"}
                                                                onChange={(e) => {
                                                                    changeHandler1(e, setCheckYes1, "radio");
                                                                }}
                                                                value={"no"}
                                                                name="yesNo1"
                                                                type="radio"
                                                                id="no1"
                                                            />
                                                            <label htmlFor="no1">No</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {checkYes1 === "yes" && (
                                                <>
                                                    <div className="from-inner-box  pt-0 ml-4">
                                                        <div className="verification-code-section">
                                                            <label>
                                                                Open zoom briefly to find 4-digit code, then
                                                                close zoom and enter the code below.
                                                                {/* Click the button / <a href='https://us05web.zoom.us/j/81367634087?pwd=JKuymCKOxmdb0s39ssxjbEw9cvseDE.1' target="_blank">link</a>
                                                                 to open Zoom and find the 4-digit code. Then, return to this window to enter the code */}
                                                                <a
                                                                    className="font-size-14 mt-1 pointer learn-more-btn"
                                                                    href="https://us05web.zoom.us/j/81367634087?pwd=JKuymCKOxmdb0s39ssxjbEw9cvseDE.1"
                                                                    target="_blank"
                                                                    onClick={() => callBackFunc1()}
                                                                >
                                                                    Get Zoom Code
                                                                </a>
                                                            </label>
                                                            <div className="d-flex gap-10 flex-wrap align-items-center">
                                                                {otp.map((digit, index) => (
                                                                    <input
                                                                        key={index}
                                                                        id={`otp-${index}`}
                                                                        type="text"
                                                                        value={digit}
                                                                        maxLength="1"
                                                                        onChange={(e) =>
                                                                            handleChangeoyp(index, e.target.value)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDownotp(index, e)
                                                                        }
                                                                        className="widtth-50-pxx  height-45 text-center text-xl border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                    />
                                                                ))}
                                                                {zoomverificationCode === "5630" && (
                                                                    <TickMark />
                                                                )}
                                                                {zoomError && (
                                                                    <div className="ml-2">
                                                                        <p className="red">
                                                                            *Incorrect code. Please try again.
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <div className="d-flex">
                                                
                                                
                                                
                                                                <input
                                                                  onChange={(e) => onChangesetZoomVerificationCode(e)}
                                                                  onBlur={handleBlur}
                                                                  className="height-45 margin-right-30"
                                                                  value={zoomverificationCode}
                                                                  type="text"
                                                                  id="codeverification"
                                                                  name="codeverification"
                                                                  placeholder="Enter Zoom verification Code"
                                                                />
                                                                {zoomverificationCode === "5630" && <TickMark />}
                                                              </div> */}
                                                            {activated && (
                                                                <span className="error"> Required</span>
                                                            )}
                                                            <div className="d-flex">
                                                                <ul className="text-left mt-3">
                                                                    <li className="line-height-20px">
                                                                        <div className="hover-container mt-2">
                                                                            <span> Why does this matter?</span>{" "}
                                                                            <span
                                                                                className="hover-target pointer red "
                                                                                tabindex="1"
                                                                            >
                                                                                Learn More
                                                                            </span>
                                                                            <aside className="hover-popup hover-popup-1">
                                                                                <p>
                                                                                    We use Zoom video conferencing to
                                                                                    connect you with your Medium.
                                                                                </p>
                                                                            </aside>
                                                                        </div>
                                                                    </li>
                                                                    <li className="line-height-20px mt-2">
                                                                        <div className="hover-container">
                                                                            <span> Not working?</span>{" "}
                                                                            <span
                                                                                className="hover-target pointer red"
                                                                                tabindex="1"
                                                                            >
                                                                                Click Here
                                                                            </span>
                                                                            <aside class="hover-popup hover-popup-2">
                                                                                <b className="text-underline">
                                                                                    Instruction :
                                                                                </b>{" "}
                                                                                Download Zoom app to your device then
                                                                                try above link again. (You do NOT need
                                                                                to open an account or sign into Zoom.)
                                                                                <a
                                                                                    className="font-size-14 mt-1 pointer width-245px learn-more-btn"
                                                                                    target="_blank"
                                                                                    href="https://zoom.us/download"
                                                                                >
                                                                                    Download Zoom app here
                                                                                </a>
                                                                            </aside>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {checkYes1 === "no" && (
                                                <div className="from-inner-box  pt-0">
                                                    <div className="w-90">
                                                        <br />
                                                        <br />
                                                        <label className="line-height-and-zoom mb-5">
                                                            <b className="font-size-20 font-weight-bold">
                                                                {" "}
                                                                Let’s get you ready so you don’t miss your
                                                                session.
                                                            </b>
                                                            <br />
                                                            {/* <br /> */}
                                                            If you haven’t used zoom before, start this audio
                                                            <img
                                                                onClick={muteAudioHandler}
                                                                className="img-play-pause pointer ml-2 mr-2"
                                                                src={mute ? VolumeMute : VolumeBtn}
                                                                alt="image"
                                                            />
                                                            <audio
                                                                ref={audioRef}
                                                                controls
                                                                loop
                                                                className="w-0"
                                                            >
                                                                <source src={AudioZoom} type="" />
                                                            </audio>
                                                            for guidance during these 3 quick steps.
                                                            <br />
                                                            <br />
                                                            Email us at{" "}
                                                            <a href="mailto:client@verysoul.com">
                                                                client@verysoul.com
                                                            </a>{" "}
                                                            if you have problems.
                                                        </label>

                                                        <div className="">
                                                            <div className="text-left line-height-and-zom-steps list-style-type-disc">
                                                                <div className="mb-5">
                                                                    <div className="zoom-grid">
                                                                        <span className="width-115-px">
                                                                            Step 1:
                                                                        </span>{" "}
                                                                        <span>
                                                                            Download{" "}
                                                                            <a
                                                                                target="_blank"
                                                                                href="https://zoom.us/download"
                                                                                onClick={callBackFunc6}
                                                                            >
                                                                                {" "}
                                                                                Zoom app
                                                                            </a>{" "}
                                                                            to device you will use for your session.
                                                                            (no account required){" "}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <div className="prvacy-box text-center mt-2 mb-2 d-flex align-items-center ">
                                                                            <div className="d-flex align-items-center justify-content-center ml-10-l">
                                                                                <div>
                                                                                    <input
                                                                                        onChange={(e) => {
                                                                                            callBackFunc("completed");
                                                                                            setconformcheck1(
                                                                                                e.target.checked
                                                                                            );
                                                                                            setconfirmcheckCantDownload(
                                                                                                e.target.checked
                                                                                                    ? false
                                                                                                    : confirmcheckCantDownload
                                                                                            );
                                                                                        }}
                                                                                        value="1"
                                                                                        id="confirmcheck1"
                                                                                        type="checkbox"
                                                                                        name="confirmcheck1"
                                                                                        checked={confirmcheck1}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="confirmcheck1"
                                                                                        className="width-unset mb-0"
                                                                                    >
                                                                                        Completed
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    {!confirmcheck1 && (
                                                                                        <span className="red font-size-12  w-100 text-center ml-1 ">
                                                                                            {" "}
                                                                                            Required
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="ml-2">
                                                                                <input
                                                                                    onChange={(e) => {
                                                                                        callBackFunc("cant_download");
                                                                                        onchangeCandDownloadHandler(e);
                                                                                    }}
                                                                                    value="1"
                                                                                    id="confirmcheckCantDownload"
                                                                                    type="checkbox"
                                                                                    name="confirmcheckCantDownload"
                                                                                    checked={confirmcheckCantDownload}
                                                                                />
                                                                                <label
                                                                                    htmlFor="confirmcheckCantDownload"
                                                                                    className="width-unset mb-0"
                                                                                >
                                                                                    Can’t download
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-5">
                                                                    <div className="zoom-grid">
                                                                        <span className="width-105-px">
                                                                            Step 2:
                                                                        </span>{" "}
                                                                        <span>
                                                                            Open zoom briefly to find 4-digit code,
                                                                            then close zoom and enter the code
                                                                            below.&nbsp;&nbsp;
                                                                            {/* Click the button / <a href='https://us05web.zoom.us/j/81367634087?pwd=JKuymCKOxmdb0s39ssxjbEw9cvseDE.1' target="_blank">link</a> to open Zoom and find the 4-digit code. Then, return to this window to enter the code. */}
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex gap-10  align-items-center ml-10-l flex-wrap  mt-2">
                                                                        <a
                                                                            className="font-size-14 mt-1 pointer learn-more-btn "
                                                                            href="https://us05web.zoom.us/j/81367634087?pwd=JKuymCKOxmdb0s39ssxjbEw9cvseDE.1"
                                                                            target="_blank"
                                                                            onClick={() => callBackFunc1()}
                                                                        >
                                                                            Get Zoom Code
                                                                        </a>
                                                                        <div className="d-flex gap-10 align-items-center ">
                                                                            {otp.map((digit, index) => (
                                                                                <input
                                                                                    key={index}
                                                                                    id={`otp-${index}`}
                                                                                    type="text"
                                                                                    value={digit}
                                                                                    maxLength="1"
                                                                                    onChange={(e) =>
                                                                                        handleChangeoyp(index, e.target.value)
                                                                                    }
                                                                                    onKeyDown={(e) =>
                                                                                        handleKeyDownotp(index, e)
                                                                                    }
                                                                                    className="widtth-50-pxx mb-2 height-45 text-center text-xl border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                        {zoomverificationCode === "5630" && (
                                                                            <TickMark />
                                                                        )}
                                                                        {zoomError && (
                                                                            <div className="ml-2">
                                                                                <p className="red">
                                                                                    *Incorrect code. Please try again.
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {activated && (
                                                                        <span className="error ml-10-l-2 w-100">
                                                                            {" "}
                                                                            Required
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="mt-4">
                                                                    <div className="zoom-grid">
                                                                        <span className="width-75-pxx">
                                                                            Step 3:
                                                                        </span>{" "}
                                                                        <span>
                                                                            Test zoom video & audio on your device,
                                                                            then close zoom.{" "}
                                                                            <a
                                                                                className=""
                                                                                target="_blank"
                                                                                href="https://zoom.us/test"
                                                                                onClick={callBackFunc7}
                                                                            >
                                                                                here
                                                                            </a>{" "}
                                                                        </span>
                                                                    </div>
                                                                    <div className="prvacy-box  ml-10-l mt-2 mb-2">
                                                                        {!confirmcheck2 && (
                                                                            <span className="error w-100 ml-10-l ">
                                                                                {" "}
                                                                                <span className="ml-10-l">
                                                                                    Required
                                                                                </span>
                                                                            </span>
                                                                        )}
                                                                        <input
                                                                            onChange={(e) => {
                                                                                callBackFunc3();
                                                                                setconformcheck2(e.target.checked);
                                                                            }}
                                                                            value="2"
                                                                            id="confirmcheck2"
                                                                            type="checkbox"
                                                                            name="confirmcheck2"
                                                                            checked={confirmcheck2}
                                                                        />
                                                                        <label
                                                                            htmlFor="confirmcheck2"
                                                                            className="width-unset"
                                                                        >
                                                                            Completed
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {/* {error && (
                                                <div className='ml-2'>
                                                    <p className="red">
                                                        *Please complete all required steps
                                                    </p>
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                    <input
                                        onClick={callBackFunc5}
                                        type="button"
                                        name="previous"
                                        class="previous action-button-previous"
                                        value="Cancel"
                                    />
                                    <input
                                        type="button"
                                        name="next"
                                        class="next action-button"
                                        value="Next"
                                        onClick={onClickHandler2}
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dont-have-acc gust-ppop">
                <div
                    className="modal fade"
                    id="myModal2-zoom"
                    role="dialog"
                    data-backdrop="static"
                >
                    <div className="modal-dialog modal-dialog-preview">
                        <div className="modal-content">
                            <div className="modal-body ">
                                <div className="guest-popup pl-5 pr-5 text-center">
                                    <h3>
                                        Sign in on the device you'll be using for your session to
                                        complete your booking.
                                    </h3>
                                    <div className="question-container">
                                        <div class="form-card">
                                            <div class="row wrap">
                                                <div class="new secend-step margin-unset">
                                                    <div class="form-group mb-2">
                                                        <input
                                                            onChange={(e) => {
                                                                changeHandlerpopup(
                                                                    e,
                                                                    setCheckYespopup,
                                                                    "radio"
                                                                );
                                                            }}
                                                            value={"yes"}
                                                            checked={checkYespopup === "yes"}
                                                            name="checkYespopup"
                                                            type="radio"
                                                            id="checkYespopup"
                                                        />
                                                        <label htmlFor="checkYespopup">
                                                            I will use this device instead
                                                        </label>
                                                    </div>
                                                    <div class="form-group mb-1">
                                                        <input
                                                            checked={checkYespopup === "no"}
                                                            onChange={(e) => {
                                                                changeHandlerpopup(
                                                                    e,
                                                                    setCheckYespopup,
                                                                    "radio"
                                                                );
                                                            }}
                                                            value={"no"}
                                                            name="checkYespopup"
                                                            type="radio"
                                                            id="checknopopup"
                                                        />
                                                        <label htmlFor="checknopopup">
                                                            Log out to resume on other device
                                                        </label>
                                                    </div>
                                                </div>
                                                {checkYespopupError && (
                                                    <div className="ml-5 mt-2">
                                                        <p className="red font-size-14">Required</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cancel-btn-check-popup-wraper">
                                        <Button
                                            className={"margin-unset try-again-book-btn"}
                                            status={0}
                                            onClick={popUpHandler}
                                            title="Continue"
                                        />
                                        {/* <Button
                                            className={"margin-unset cancel-btn-check-popup"}
                                            status={0}
                                            onClick={logoutUser}
                                            title="Logout"
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/************* */}
            <div className="dont-have-acc gust-ppop">
                <div
                    className="modal fade"
                    id="myModal2-zoom-free-session"
                    role="dialog"
                    data-backdrop="static"
                >
                    <div className="modal-dialog modal-dialog-preview">
                        <div className="modal-content">
                            <div className="modal-body ">
                                <div className="guest-popup pl-5 pr-5 text-center">
                                    <h3>
                                        We’d be happy to add you to our waitlist for upcoming
                                        zoom-free sessions.
                                    </h3>

                                    <div className="cancel-btn-check-popup-wraper">
                                        <Button
                                            className={"margin-unset try-again-book-btn"}
                                            status={0}
                                            onClick={() => {
                                                window.loadModal2(
                                                    "#myModal2-zoom-free-session",
                                                    "hide"
                                                );
                                                setconfirmcheckCantDownload(false);
                                            }}
                                            title="Go Back"
                                        />
                                        <Button
                                            className={"margin-unset cancel-btn-check-popup"}
                                            status={0}
                                            onClick={putMeOnWitlistHandler}
                                            title="Put me on Waitlist
"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/************* */}
            <div className="dont-have-acc gust-ppop">
                <div
                    className="modal fade"
                    id="myModal2-zoom-free-session-thanks"
                    role="dialog"
                    data-backdrop="static"
                >
                    <div className="modal-dialog modal-dialog-preview">
                        <div className="modal-content">
                            <div className="modal-body ">
                                <div className="guest-popup pl-5 pr-5 text-center">
                                    <h2 className=" ">
                                        Thank you for your interest, and we apologize for the
                                        inconvenience.
                                        <br />
                                        <br />
                                        We will reach out to you once zoom-free sessions become
                                        available.
                                    </h2>

                                    {/* <div className="cancel-btn-check-popup-wraper">

                                        <Button
                                            className={"margin-unset try-again-book-btn"}
                                            status={0}
                                            onClick={() => { window.loadModal2('#myModal2-zoom-free-session', 'hide'); setconfirmcheckCantDownload(false) }}
                                            title="Go Back"
                                        />
                                        <Button
                                            className={"margin-unset cancel-btn-check-popup"}
                                            status={0}
                                            onClick={() => { window.loadModal2('#myModal2-zoom-free-session', 'hide'); setconformcheck1(false) }}
                                            title="Put me on Waitlist
"
                                        />

                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

{
    /* <div class="container-fluid question-container">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                <form id="msform">
                    <fieldset>
                        <div class="form-card">
                            <div class="row wrap">
                                <h2>Zoom Test</h2>
                                <h3>Are you a regular Zoom user ?</h3>
                                <div class="new secend-step">
                                    <div class="form-group">
                                        <input
                                            onChange={(e) => {
                                                changeHandler(e, setCheckYes, "radio");
                                            }}
                                            value={"yes"}
                                            checked={checkYes === "yes"}
                                            name="yesNo"
                                            type="radio"
                                            id="yes"
                                        />
                                        <label htmlFor="yes">Yes</label>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            checked={checkYes === "no"}
                                            onChange={(e) => {
                                                changeHandler(e, setCheckYes, "radio");
                                            }}
                                            value={"no"}
                                            name="no"
                                            type="radio"
                                            id="no"
                                        />
                                        <label htmlFor="no">No</label>
                                    </div>
                                </div>
    
                                {checkYes === "no" && (
                                    <>
                                        <h3>
                                            If no, please test your ability to connect with Zoom
                                            here
                                        </h3>
                                        <a href="https://zoom.us/test" target={"_blank"}>
                                            https://zoom.us/test
                                        </a>
                                        <p className="red">  &nbsp;(Only for testing audio, video and sound.)</p>
                                        <h3>Please check each box to confirm :</h3>
                                        <div class="new secend-step">
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheck4);
                                                    }}
                                                    checked={check4}
                                                    name="zoom"
                                                    type="checkbox"
                                                    id="zoom"
                                                />
                                                <label for="zoom">Zoom audio worked</label>
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheck5);
                                                    }}
                                                    checked={check5}
                                                    name="worked"
                                                    type="checkbox"
                                                    id="worked"
                                                />
                                                <label for="worked">Zoom microphone worked</label>
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    onChange={(e) => {
                                                        changeHandler(e, setCheck6);
                                                    }}
                                                    checked={check6}
                                                    name="video"
                                                    type="checkbox"
                                                    id="video"
                                                />
                                                <label for="video">Zoom video worked</label>
                                            </div>
                                            {error && (
                                                <div>
                                                    <p className="red">
                                                        *Please Select all check boxes
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <input
                            onClick={onCancelQuestions}
                            type="button"
                            name="previous"
                            class="previous action-button-previous"
                            value="Cancel"
                        />
                        <input
                            type="button"
                            name="next"
                            class="next action-button"
                            value="Next"
                            onClick={onClickHandler2}
                        />
    
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
    </div> */
}
