import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

export default function StepTwo({
  onCancelQuestions,
  setRenderType,
  getSessionTerms,
  bookInfo = undefined,
  practitionerInfo,
  url = undefined,
  boo,
  setSaveTerms,
  saveterms,
  bookingType,
  getSubsessions,
  activeSessionId,
  getPractitionarsList,
  activesession_id,
  openBackfillpopup
}) {
  const auth = useSelector((state) => state.user);
  const [error, setError] = useState(false);
  const [checkall, setCheckAll] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);

  const [check7, setCheck7] = useState(false);

  const [check8, setCheck8] = useState(false);
  const [check9, setCheck9] = useState(false);
  const [check10, setCheck10] = useState(false);
  const [check11, setCheck11] = useState(false);
  const [check12, setCheck12] = useState(false);
  const [check13, setCheck13] = useState(false);
  const [check14, setCheck14] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [check16, setCheck16] = useState(false);
  const [check17, setCheck17] = useState(false);
  // const [checkYes, setCheckYes] = useState(false);
  // const [checkNo, setCheckNo] = useState(false);

  useEffect(() => {
    bookingType !== "outreach" && setCheck16(true);
  }, []);
  const changeHandler = (e, setCheck, type) => {
    // if (type === "radio") {
    // setCheckYes(e.target.value);
    // }
    setCheck(e.target.checked);

    // if(check1 && check2 && check3 && check7 && check8 && check9 && check10 && check11 && check12 && check13 && check14){
    //   setCheckAll(e.target.checked);
    // }
    // else{
    //   setCheckAll(e.target.checked);
    // }
    // else if(!check1 || !check2 || !check3 || !check7 || !check8 || !check9 || !check10 || !check11 || !check12 || !check13 || !check14){
    //   setCheckAll(false);
    // }
  };

  const changeHandlerAll = (e) => {
    setCheckAll(e.target.checked);
    setCheck1(e.target.checked);
    setCheck2(e.target.checked);
    setCheck3(e.target.checked);
    setCheck7(e.target.checked);
    setCheck8(e.target.checked);
    setCheck9(e.target.checked);
    setCheck10(e.target.checked);
    setCheck11(e.target.checked);
    setCheck12(e.target.checked);
    setCheck13(e.target.checked);
    setCheck14(e.target.checked);
    setCheck16(e.target.checked);
    setCheck17(e.target.checked);
  };

  const validator = () => {
    if (bookingType === "outreach") {
      return (
        check1 &&
        check2 &&
        check7 &&
        check8 &&
        check9 &&
        check10 &&
        check11 &&
        check12 &&
        check13 &&
        check14 &&
        check3 &&
        check16 &&
        check17
      );
    } else {
      return (
        check1 &&
        check2 &&
        check7 &&
        check8 &&
        check9 &&
        check10 &&
        check11 &&
        check12 &&
        check13 &&
        check14 &&
        check3 &&
        check17
      );
    }

  };

  const onClickSaveTerms = () => {
    console.log("validate", validator());

    if (validator()) {
      setError(false);

      if (activesession_id === "5" && auth.user.terms_session_status !== 1) {
        setSaveTerms(1);
        getSessionTerms(1);
      }
      if (activesession_id === "3" && auth.user.terms_session_starter_status !== 1) {
        getSessionTerms(1);
      }
    } else {
      setError(true);
    }
  };

  const onClickHandler = () => {
    console.log("validate", validator());
    if (validator()) {
      setError(false);

      if (activesession_id === "5" && auth.user.terms_session_status !== 1) {
        setSaveTerms(1);
        getSessionTerms(1);
      }
      if (activesession_id === "3" && auth.user.terms_session_starter_status !== 1) {
        getSessionTerms(1);
      }

      console.log(bookingType, "bookingType");
      if (bookingType === "outreach") {
        openBackfillpopup('newappointment');
        // setRenderType("newappointment");
        localStorage.setItem("type", "newappointment");
      } else if (bookingType === "starter") {
        setRenderType("newappointment");
        localStorage.setItem("type", "newappointment");
      }
      else {
        if (bookingType == "professional") {
          // openBackfillpopup('');
          getPractitionarsList()
        }
        // getPractitionarsList()
      }
    } else {
      setError(true);
    }
  };

  return (
    <div class="container-fluid question-container">
      {console.log(activesession_id, "activesession_id")}
      <div class="row justify-content-center">
        <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
          <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
            <form id="msform">
              <fieldset>
                <div class="form-card">
                  <div class="row wrap">
                    <h2 className="text-center">
                      Please read and confirm each item:
                    </h2>
                    {(activesession_id === "5" && auth.user.terms_session_status === 1) || (activesession_id === "3" && auth.user.terms_session_starter_status === 1) ? (
                      <div class="new margin-unset">
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              changeHandlerAll(e);
                            }}
                            checked={checkall}
                            name="checkall"
                            type="checkbox"
                            id="checkall"
                          />
                          <label htmlFor="checkall">Agree To All</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <h2>I am:</h2>
                    <div class="new margin-unset">
                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck1);
                          }}
                          checked={check1}
                          name="check1"
                          type="checkbox"
                          id="check1"
                        />
                        <label htmlFor="check1">Open to this experience2</label>
                      </div>
                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck2);
                          }}
                          checked={check2}
                          name="check2"
                          type="checkbox"
                          id="check2"
                        />
                        <label htmlFor="check2">
                          Ready to respond to potential evidence with “yes”,
                          “no”, “possibly” or “I don’t know”
                        </label>
                      </div>
                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck17);
                          }}
                          checked={check17}
                          name="check17"
                          type="checkbox"
                          id="check17"
                        />
                        <label htmlFor="check17">
                          Using a device with the Zoom app already downloaded (No account needed, just the app.)</label>
                      </div>
                      <h2>I will:</h2>
                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck7);
                          }}
                          checked={check7}
                          name="check7"
                          type="checkbox"
                          id="check7"
                        />
                        <label htmlFor="check7">
                          Attend in a quiet space where I am{" "}
                          <span className="text-underline">
                            not distracted.
                          </span>
                        </label>
                      </div>
                      <div class="form-group">
                        <input
                          checked={check8}
                          onChange={(e) => {
                            changeHandler(e, setCheck8);
                          }}
                          name="check8"
                          type="checkbox"
                          id="check8"
                        />
                        <label htmlFor="check8">
                          Attend alone to enable focus on my loved ones. (unless
                          a 2-person session is selected)
                        </label>
                      </div>

                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck14);
                          }}
                          checked={check14}
                          name="check14"
                          type="checkbox"
                          id="check14"
                        />
                        <label htmlFor="check14">
                          {" "}
                          <span className="text-underline">
                            {" "}
                            Not record
                          </span>{" "}
                          without permission from the Medium. (any recordings
                          are for private use only)
                        </label>
                      </div>
                      {/*****In understabd */}
                      <h2>I understand that:</h2>
                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck9);
                          }}
                          checked={check9}
                          name="check9"
                          type="checkbox"
                          id="check9"
                        />
                        <label htmlFor="check9">
                          There are no guarantees of who will come through in my
                          session.
                        </label>
                      </div>
                      <div class="form-group">
                        <input
                          checked={check10}
                          onChange={(e) => {
                            changeHandler(e, setCheck10);
                          }}
                          name="check10"
                          type="checkbox"
                          id="check10"
                        />
                        <label htmlFor="check10">
                          I am the best interpreter of the information conveyed
                          by my loved ones.
                        </label>
                      </div>

                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck11);
                          }}
                          checked={check11}
                          name="check11"
                          type="checkbox"
                          id="check11"
                        />
                        <label htmlFor="check11">
                          {" "}
                          Post-session recognition of evidence is normal and may
                          serve a purpose.
                        </label>
                      </div>
                      {/*****I agree that */}
                      <h2>I agree that:</h2>

                      <div class="form-group">
                        <input
                          onChange={(e) => {
                            changeHandler(e, setCheck12);
                          }}
                          checked={check12}
                          name="check12"
                          type="checkbox"
                          id="check12"
                        />
                        <label htmlFor="check12">
                          I will show up a few minutes early for the session
                          that I schedule.
                        </label>
                      </div>
                      {bookingType === "outreach" && (
                        <div class="form-group">
                          <input
                            checked={check16}
                            onChange={(e) => {
                              changeHandler(e, setCheck16);
                            }}
                            name="check16"
                            type="checkbox"
                            id="check16"
                          />
                          <label htmlFor="check16">
                            I must reconfirm at least 2 hours before through the
                            24-hour reminder email
                          </label>
                        </div>
                      )}
                      <div class="form-group">
                        <input
                          checked={check13}
                          onChange={(e) => {
                            changeHandler(e, setCheck13);
                          }}
                          name="check13"
                          type="checkbox"
                          id="check13"
                        />
                        <label htmlFor="check13">
                          I will complete the emailed post-session survey
                          (checking spam/junk folders).
                        </label>
                      </div>
                      <div class="form-group">
                        <input
                          checked={check3}
                          onChange={(e) => {
                            changeHandler(e, setCheck3);
                          }}
                          name="check3"
                          type="checkbox"
                          id="check3"
                        />
                        <label htmlFor="check3">
                          I take sole responsibility for my interpretations,
                          choices, and actions.
                        </label>
                      </div>

                      {error && (
                        <div>
                          <p className="red">*Please Select all check boxes</p>
                        </div>
                      )}
                      <div></div>
                    </div>
                  </div>
                </div>
                {/* {!showBtn && ( */}
                <input
                  onClick={onCancelQuestions}
                  type="button"
                  name="previous"
                  class="previous action-button-previous"
                  value="Cancel"
                />
                {/* )} */}

                {/* {!showBtn ? ( */}
                <>
                  {url !== undefined && url ? (
                    <a
                      href={
                        !validator()
                          ? "javascript:void(0);"
                          : "https://appointments.verysoul.com/reserve/donated-free"
                      }
                      className={`next action-button`}
                      onClick={onClickSaveTerms}
                    >
                      Next
                    </a>
                  ) : (
                    <input
                      onClick={() => onClickHandler()}
                      type="button"
                      name="next"
                      class="next action-button"
                      value="Next"
                    />
                  )

                    // : bookInfo == undefined ? (
                    //   <input
                    //     onClick={()=>onClickHandler()}
                    //     type="button"
                    //     name="next"
                    //     class="next action-button"
                    //     value="Next"
                    //   />
                    // ) : (
                    //   <a
                    //     className="action-button"
                    //     // staging
                    //     onClick={practitionerInfo.general.show_flexbooker ? () => { } : () => onClickHandler()}

                    //     // live
                    //     href={
                    //       practitionerInfo.general.show_flexbooker ?
                    //         practitionerInfo.general.personal_booking_link !== "" &&
                    //           validator()
                    //           ? practitionerInfo.general.personal_booking_link
                    //           : "javascript:void(0);"

                    //         : "javascript:void(0);"
                    //     }
                    //   >
                    //     Book Now
                    //     {console.log(practitionerInfo, "practitionerInfo")}
                    //   </a>
                    // )
                  }
                </>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
