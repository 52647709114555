import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
//subcomponents
import StepOne from "./profilestep/stepone";
//services
import { getCountry, getLanguages, getTimezones } from "../../services/uidata";
//data
import { stepOneData, stepTwoData } from "./data";
import StepTwo from "./profilestep/steptwo";
import { getProfileBasicInfo } from "../../services/medium";
import StepZero from "./profilestep/stepzero";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../reducer/auth/auth";
import { updateDetectedTimezone } from "../../services/profile";

export default function Profile() {
  const dispatch = useDispatch();
  const { is_starter } = useSelector(
    (state) => state.user.user
  );
  //localstates
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    stepOneData,
    stepTwoData,
  });
  const [profileSection, setProfileSection] = useState(null);
  const [formLanguages, setFormLanguages] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [countryOnly, setcountryOnly] = useState(null);
  const [timezoneOnly, settimezoneOnly] = useState(null);

  //sideeffects
  useEffect(() => {
    dispatch(setLoading(true));
    // initialValues.timezone = moment.tz.guess();
    // // getZone();
    updateCountries();
    updateLanguage();
    updateTimezones();
    if (profileSection == null) getProfile();
  }, []);

  const updateIncomingHandler = (items, type) => {
    let incomingArray;
    if (type === "languages") {
      incomingArray = items.map((item) => {
        return {
          ...item,
          id: item.language_id,
          value: item.language_id,
          label: item.lanugage_name,
        };
      });
    }
    return incomingArray;
  };
  // useEffect(() => {
  //   console.log(timezoneData, "timezoneData" )
  //   console.log(stepOneData.timezone,"stepOneData");
  //   if (timezoneData.length > 0) {
  //   settimezoneOnly(null);
  //   if (stepOneData.timezone.id === "" || stepOneData.timezone ==="") {
  //     let newtime = moment.tz.guess()
  //     console.log('Detected Timzone' ,newtime)
  //     let item = timezoneData.filter((time) => time.name === newtime);
  //     console.log('filtered Timzone' ,item, )
  //     if (item.length && item[0].status === 1) {

  //       stepOneData.timezone = { ...item[0], value: item[0].id, label: item[0].front_name };
  //     }
  //     else {
  //       let info = getNextMatchingTimezone(timezoneData ,newtime)
  //       console.log('Next 1 status timezone',info)
  //       stepOneData.timezone = { ...info, value: info.id, label: info.front_name };
  //     }


  //     settimezoneOnly("settimezoneOnly");
  //   }
  //   else{
  //     settimezoneOnly("settimezoneOnly");
  //   }
  // }
  // }, [timezoneData])

  // const getNextMatchingTimezone = (timezoneData, newtime) => {
  //   const array = timezoneData;
  //   const currentValue = newtime;

  //   const currentIndex = array.findIndex(obj => obj.name === currentValue);
  //   const nextMatchingIndex = array.findIndex((obj, index) => index > currentIndex && obj.status === 1);
  //   const nextMatchingObject = nextMatchingIndex !== -1 ? array[nextMatchingIndex] : null;

  //   return nextMatchingObject;

  // }
  useEffect(() => {
    settimezoneOnly(null);
    if ((stepOneData.timezone.id === "" || stepOneData.timezone == '') && timezoneData.length > 0) {
      let newtime = moment.tz.guess()
      let item = timezoneData.filter((time) => time.name === newtime);
      if (item.length) {

        stepOneData.timezone = { ...item[0], value: item[0].id, label: item[0].front_name };
        let body = {
          timezone: moment.tz.guess(),
          is_matched: 1
        }
        updateDetectedTimezone(body).then(() => { }).catch(() => { })

      }
      else {
        setTimeout(() => {

          dispatch(setLoading(false))
          let body = {
            timezone: moment.tz.guess(),
            is_matched: 0
          }
          updateDetectedTimezone(body).then(() => { }).catch(() => { })

        }, 4000);
      }
      dispatch(setLoading(false))
      settimezoneOnly("settimezoneOnly");

    }
    else {
      setTimeout(() => {

        dispatch(setLoading(false))
        // let body = {
        //   timezone:moment.tz.guess(),
        //   is_matched: 0
        // }
        // updateDetectedTimezone(body).then(()=>{}).catch(()=>{})

      }, 4000);
    }
    settimezoneOnly("settimezoneOnly");

  }, [timezoneData, stepOneData])
  const getProfile = () => {
    getProfileBasicInfo()
      .then((data) => {
        const {
          country,
          first_name,
          last_name,
          languages,
          phone,
          whatsapp,
          timezone,
          screen_name,
        } = data.data.data;
        stepOneData.first_name = first_name;
        stepOneData.last_name = last_name;
        stepOneData.country = country;
        stepOneData.phone = phone;
        stepOneData.languages = updateIncomingHandler(languages, "languages");
        // stepOneData.timezone = timezone.front_name !== "" ? { ...timezone, value: timezone.id, label: timezone.front_name } : { id: "", front_name: moment.tz.guess() };

        stepOneData.timezone = timezone.front_name !== "" && stepOneData.timezone.id !== "" ?
          { ...timezone, value: timezone.id, label: timezone.front_name }
          : stepOneData.timezone;
        stepOneData.whatsapp = whatsapp;
        stepOneData.screen_name = screen_name;
        setcountryOnly("setcountryOnly");
        settimezoneOnly("settimezoneOnly");
        console.log(moment.tz.guess(), "timezone", timezone)
        setProfileSection(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const updateValues = (data, step) => {
    if (step === 1) {
      const new_obj = { ...values, stepOneData: data };
      setValues(new_obj);
    }
    if (step === 2) {
      const new_obj = { ...values, stepTwoData: data };
      setValues(new_obj);
    }
  };
  const NextStep = () => step !== 2 && setStep(step + 1);

  const PreviousStep = () => step !== 0 && setStep(step - 1);
  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        setFormLanguages(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateCountries = () => {
    getCountry()
      .then((data) => {
        setCountryData(data.data.data);
      })
      .catch((err) => console.log(err));
  };

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        setTimezoneData(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const RenderedComponent = () => {
    if (is_starter == 1) {

      switch (step) {

        case 0:
          return (
            <StepZero
              NextStep={NextStep}
            />
          )
        case 1:
          return (
            <StepOne
              formLanguages={formLanguages}
              countryData={countryData}
              setValues={updateValues}
              // value={values}
              stepOneData={values.stepOneData}
              // stepTwoData={values.stepTwoData}

              NextStep={NextStep}
              PreviousStep={PreviousStep}
              countryOnly={countryOnly}
              timezoneData={timezoneData}
              timezoneOnly={timezoneOnly}
            />
          );
        case 2:
          return (
            <StepTwo
              // setValues={updateValues}
              // value={values}
              stepTwoData={values.stepTwoData}
              // SelectValues={SelectValues}
              NextStep={NextStep}
              PreviousStep={PreviousStep}
            />
          );
        default:
          return (
            <></>
          )
      }
    }
    else {
      switch (step) {


        case 0:
          return (
            <StepOne
              formLanguages={formLanguages}
              countryData={countryData}
              setValues={updateValues}
              // value={values}
              stepOneData={values.stepOneData}
              // stepTwoData={values.stepTwoData}

              NextStep={NextStep}
              PreviousStep={PreviousStep}
              countryOnly={countryOnly}
              timezoneData={timezoneData}
              timezoneOnly={timezoneOnly}
            />
          );
        case 1:
          return (
            <StepTwo
              // setValues={updateValues}
              // value={values}
              stepTwoData={values.stepTwoData}
              // SelectValues={SelectValues}
              NextStep={NextStep}
              PreviousStep={PreviousStep}
            />
          );
        default:
          return (
            <></>
          )
      }
    }
  };
  return (
    <div className="from-saction">
      <div className="container">
        <div className="row justify-content-center">
          <div id="msform">
            <div className="header-from">
              {console.log(step)}
              <ul id="progressbar">

                {is_starter !== 0 && <li className={"active"} id="personal">  <span>
                  <img
                    src="images/sating.png"
                    className="black"
                    alt="dark "
                  />
                  <img
                    src="images/sating-wight.png"
                    className="wight"
                    alt="light"
                  />
                </span>
                  <strong>Preferences</strong>
                </li>}
                <li className={step > 0 ? "active" : ""} id="account">
                  <span>
                    <img
                      src="images/step-user-black.png"
                      className="black"
                      alt="user dark"
                    />
                    <img
                      src="images/step-user.png"
                      className="wight"
                      alt="user light"
                    />
                  </span>
                  <strong>Profile</strong>
                </li>

                <li className={step > 1 ? "active" : ""} id="confirm">
                  <span>
                    <img
                      src="images/privacy.png"
                      className="black"
                      alt="dark"
                    />
                    <img
                      src="images/privacy-wight.png"
                      className="wight"
                      alt="light"
                    />
                  </span>
                  <strong>Registration</strong>
                </li>
              </ul>
            </div>
            <div className="body-from from-step-new">{RenderedComponent()}</div>
          </div>
        </div>
      </div>
      {/* <div className="loader-overlay">
      <GridLoader />
    </div> */}
    </div>
  );
}
