const stepOneData = {
  first_name: "",
  last_name: "",
  phone: "",
  whatsapp: "",
  country: "",
  languages: [],
  timezone: "",
  screen_name: "",
  timeconfirmcheck: false,

};

const stepTwoData = {
  agree_to_legal_terms_and_conditionss: false,
  //   agree_to_starter_client_guidelines: false,
  opt_in_out: false,
  zoom_code_yes_no: '',
  zoom_code_yes_no2: '',

};
export { stepOneData, stepTwoData };
