import React, { useEffect } from 'react'
import { emailReverification } from '../../../../services/admin';
import { GridLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function VerifyEmail() {
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const myParam = queryParams.get('token');
        console.log(myParam,"myParam myParam myParam myParam myParam myParam", myParam);
        // localStorage.removeItem("access_token"); 
        
        if (myParam) {


            emailReverification(myParam).then((data) => {
                if (data.data.status) {
                    toast.success(data.data.message, { position: 'top-center' });
                    localStorage.clear();
                    navigate('/?token=' + data.data.data.access_token)
                    window.location.reload();
                }
                else {
                    toast.error(data.data.message, { position: 'top-center' });
                    setTimeout(() => {
                        navigate('/')
                    }, 3000)
                }

            }).catch(() => {

            })
        }
        else {
            navigate('/')
            
        }
    })
    return (
        <div className="spinner">
            <GridLoader size={50} />
        </div>
    )
}
